import React, {useEffect, useState} from 'react'
import {PageContainer} from '../../layout/drawerMenuOptions'
import {Text} from '../../common/text'
import styled from 'styled-components'
import {ButtonCustom} from '../../common/button'
import {ProvidersForm} from './providersForm'
import {deleteFile, useSuppliers} from '../../../hooks/suppliers.hook'
import {useMutation, useQueryClient} from 'react-query'
import {FileIcon, defaultStyles} from 'react-file-icon'
import {DataGrid} from '@mui/x-data-grid';
import {format} from 'date-fns'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {useHistory} from 'react-router-dom'
import {Alert} from "@material-ui/lab";
import {CalendarCustom} from "../../common/inputs/muicalendar";
import {IconButton, Paper, Snackbar} from "@mui/material";
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

function ProvidersContent() {
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState("0")
  const [files, setFiles] = useState([])
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [filter, setFilter] = useState({
    start_date: null,
    end_date: null
  })
  const [searchParams, setSearchParams] = useState('')

  useEffect(() => {
    let searchParamsTmp = new URLSearchParams();
    if (filter.start_date) {
      searchParamsTmp.set("start_date", filter.start_date);
      setSearchParams(`&${searchParamsTmp.toString()}`)
    }
    if (filter.end_date) {
      searchParamsTmp.set("end_date", filter.end_date);
      setSearchParams(`&${searchParamsTmp.toString()}`)
    }
    if (!filter.start_date && !filter.end_date) {
      setSearchParams('')
    }
  }, [filter])

  const {getFiles} = useSuppliers({page, search: searchParams})

  let history = useHistory()

  const {
    isLoading,
    data,
    isPreviousData,
    isFetching
  } = getFiles

  useEffect(() => {
    if (data && !isPreviousData) {
      const filesTmp = [...data.files]
      setFiles(filesTmp)
    }
  }, [data, isFetching])

  const columns = [
    {
      field: 'url', headerName: 'Fichier', width: 130, renderCell: (params) => {
        const extension = params.value.split('?')[0].split('.').reverse()[0]
        return (
          <WrapperIcon href={params.value} target="_blank">
            <FileIcon
              extension={`.${extension}`} {...defaultStyles[extension]}
              style={{height: '60%'}}/>
          </WrapperIcon>)
      }
    },
    {
      field: 'filename',
      headerName: 'Nom du fichier',
      width: 400, renderCell: (params) => (
        <p>{params.value.split('/').reverse()[0]}</p>)
    },
    {
      field: 'createdAt',
      headerName: 'Date d\'ajout',
      width: 170,
      renderCell: (params) => (
        <p>
          {format(new Date(params.value), 'dd/MM/yyyy')}
        </p>)
    },
    {
      field: 'id',
      headerName: 'Supprimer',
      width: 150,
      renderCell: (params) => (
        <DeleteFile id={params.value} page={page}/>
      )
    }
  ]
  const toggle = () => {
    setOpen(open => !open)
  }

  const [queryParamsValue, setQueryParamsValue] = useState({
    start_date: null,
    end_date: null
  })

  useEffect(() => {
    const defaultUrl = new URLSearchParams()
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        defaultUrl.set(key, queryParamsValue[key])
      }
    })

    history.push({
      pathname: '/providers',
      search: defaultUrl.toString()
    })
  }, [filter])

  if (!data) {
    return null
  }

  return (
    <PageContainer>
      <RemindersContainer>
        <InfosContainer>
          <Paper elevation={1} style={{width: '100%'}}>
            <TitleContainer>
              <Text variant="h5" marginBottom
                    color="primary">{data.total} {data.total > 1 ? 'Documents' : 'Document'}</Text>
              <ButtonCustom
                onClick={toggle}
                background="blue"
                height="40"
                startIcon={<AddIcon/>}
                label="Ajouter un document"
              />
            </TitleContainer>
            <ContentContainer>
              <FilterContainer>
                <CalendarCustom
                  label="Date de début"
                  onChange={(e) => {
                    setQueryParamsValue(old => ({...old, start_date: e ? new Date(e).toDateString() : null}))
                    setFilter(oldFilter => ({...oldFilter, start_date: e ? new Date(e).toDateString() : null}))
                  }}
                  littlePadding
                  dateDefault={filter.start_date}
                />
                <CalendarCustom
                  label="Date de fin"
                  littlePadding
                  dateDefault={filter.end_date}
                  onChange={(e) => {
                    setQueryParamsValue(old => ({...old, end_date: e ? new Date(e).toDateString() : null}))
                    setFilter(oldFilter => ({...oldFilter, end_date: e ? new Date(e).toDateString() : null}))
                  }}
                />
              </FilterContainer>
              <ButtonCustom
                onClick={() => setFilter({
                  start_date: null,
                  end_date: null
                })}
                disabled={!filter.start_date && !filter.end_date}
                background="orange"
                height="30"
                startIcon={<FilterListOffIcon/>}
                label="Réinitialiser les filtres"
              />
            </ContentContainer>
            <ContentContainer>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: '100%', height: 600}}>
                  <DataGrid
                    rows={files}
                    columns={columns}
                    pagination
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    rowCount={data.total}
                    paginationMode="server"
                    onPageChange={(page) => {
                      setPage(page)
                    }}
                    loading={isLoading}
                  />
                </div>
              </div>
              <ProvidersForm
                setSuccessMessage={(e) => setSuccessMessage(e)}
                setErrorMessage={(e) => setErrorMessage(e)}
                open={open}
                toggle={toggle}/>
              <Snackbar
                open={successMessage}
                onClose={() => setSuccessMessage(null)}
                autoHideDuration={6000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Alert variant="filled" severity="success">
                  {successMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                open={errorMessage}
                onClose={() => setErrorMessage(null)}
                autoHideDuration={6000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </ContentContainer>
          </Paper>
        </InfosContainer>
      </RemindersContainer>
    </PageContainer>
  )
}

function DeleteFile({id, page}) {
  const queryClient = useQueryClient()
  const {mutate, isLoading} = useMutation('delete_provider', (id) => deleteFile(id), {
    onMutate: () => queryClient.removeQueries(['supplierFile', page - 1]),
    onSuccess: () => {
      queryClient.invalidateQueries('supplierFile').then(r => r)
    }
  })

  const remove = (id) => {
    mutate(id)
  }
  return (
    <IconButton size="small" onClick={() => remove(id)} disabled={isLoading}>
      <DeleteIcon fontSize="small" color="error"/>
    </IconButton>
  )
}

const RemindersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const InfosContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 30px 50px 30px;
  justify-content: flex-start;
`

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const WrapperIcon = styled.a`
  svg {
    max-height: 100%;
  }

  height: 70%;
  width: auto;
`

export {
  ProvidersContent
}






