import {createTheme} from '@mui/material/styles';
import {deepPurple, green, lightGreen, orange, red, yellow} from "@mui/material/colors";
import {frFR} from "@mui/material/locale";

const theme = createTheme({
  palette: {
    primary: {
      main: '#2a3b93'
    },
    secondary: {
      main: '#172B4D'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      'Fira Sans',
      ' "Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif'
    ].join(',')
  },
  red: red[500],
  purple: deepPurple[600],
  default: '#f6f7f9',
  orange: orange[500],
  green: green[500],
  lightGreen: lightGreen[300],
  lightBlue: '#deecff',
  blue: '#2a3b93',
  yellow: yellow[500],
  dark: '#172B4D',
  black: '#000000',
  white: 'white',
  grey: '#c4c4c4',
  fafa: '#fafafa'
}, frFR)

export {
  theme
}
