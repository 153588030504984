import React from 'react'
import {MandateContent} from '../components/contentPages/mandate'

function Mandate({openDrawer}) {
  return (
    <MandateContent openDrawer={openDrawer}/>
  )
}

export {
  Mandate
}
