import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PageContainer } from '../../layout/drawerMenuOptions'
import { Text } from '../../common/text'
import { DataGrid } from '@mui/x-data-grid';
import { SelectText } from '../../common/inputs/inputSelectWithText'
import { ChipCustom } from '../../common/chip'
import { ButtonCustom } from '../../common/button'
import GetAppIcon from '@mui/icons-material/GetApp';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import { contractorOptions, status } from '../../../data/data'
import { Link, useHistory } from 'react-router-dom'
import { Spacer } from '../../common/spacer'
import { Loader } from '../../common/loader'
import { useDevise } from '../../common/utils'
import { format } from 'date-fns'
import * as fs from 'file-saver'
import { saveAs } from 'file-saver'
import { Workbook } from 'exceljs'
import JSZip from 'jszip'
import axios from 'axios'
import CreateIcon from '@mui/icons-material/Create'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton, Paper } from "@mui/material"
import { CalendarCustom } from "../../common/inputs/muicalendar";
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

function BillingContent({data, dataInvoices, isLoading, setPage, totalInvoices, setSearchParams}) {
  const [contractName, setContractName] = useState(null)
  const [select, setSelection] = useState([])

  let history = useHistory()

  const columns = [
    {
      field: 'ref',
      headerName: 'Réf',
      width: 110,
      renderCell: (params) => (
        <>
          <IconButton size="small" onClick={() => history.push(`/billing/bill?id=${params.row.id}`)}>
            {params.row.status === 'DRAFT' ?
              <CreateIcon fontSize="small" color="primary"/>
              :
              <VisibilityIcon fontSize="small" color="primary"/>
            }
          </IconButton>
          <a style={{fontSize: '12px', fontWeight: 'bold', marginLeft: '2px'}} href={params.value.url} target="_blank">
            {params.value?.invoice_number || '❌'}
          </a>
        </>
      )
    },
    {
      field: 'contractor',
      headerName: 'Mandataire',
      width: 64,
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    },
    {
      field: 'contract_name',
      headerName: 'Mandat',
      width: 105,
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    },
    {
      field: 'spv',
      headerName: 'SPV',
      type: 'number',
      width: 300,
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    },
    {
      field: 'status',
      headerName: 'Statut',
      type: 'number',
      width: 100,
      renderCell: (params) => (displayStatus(params.value))
    },
    {
      field: 'amount_ht',
      headerName: '€ HT',
      width: 115,
      type: 'number',
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    },
    {
      field: 'amount_ttc',
      headerName: '€ TTC',
      width: 115,
      type: 'number',
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    },
    {
      field: 'creation_date',
      headerName: 'Date facturation',
      width: 130,
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    },
    {
      field: 'cashed',
      headerName: '€ Réglé',
      width: 115,
      type: 'number',
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    },
    {
      field: 'settlement_date',
      headerName: 'Date règlement',
      width: 130,
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    },
    {
      field: 'cross_checking',
      headerName: 'Auto',
      width: 90,
      renderCell: (params) => (<TextTab>{params.value}</TextTab>)
    }
  ]

  const rows = createRow(dataInvoices)

  const DataExcel = (data) => {
    let parsedData = []
    data.forEach((e) => {
      const newItem = {
        ref: e.ref.invoice_number,
        contractor: e.contractor,
        contract_name: e.contract_name,
        spv: e.spv,
        status: e.status,
        amount_ht: e.amount_ht,
        amount_ttc: e.amount_ttc,
        creation_date: e.creation_date,
        cashed: e.cashed,
        settlement_date: e.settlement_date,
        cross_checking: e.cross_checking
      }
      parsedData = [...parsedData, newItem]
    })
    return parsedData
  }

  const [filter, setFilter] = useState({
    contractor: null,
    contract: null,
    status: null,
    billing_entity: null,
    start_date: null,
    end_date: null
  })

  const [queryParamsValue, setQueryParamsValue] = useState({
    contractor: null,
    contract: null,
    status: null,
    billing_entity: null,
    start_date: null,
    end_date: null
  })

  useEffect(() => {
    const searchParamsTmp = new URLSearchParams();
    if (filter.contractor) {
      searchParamsTmp.set("contractor", filter.contractor.value);
      setSearchParams(`&${searchParamsTmp.toString()}`)
    }
    if (filter.contract) {
      searchParamsTmp.set("contract", filter.contract.contract_name);
      setSearchParams(`&${searchParamsTmp.toString()}`)
    }
    if (filter.status) {
      searchParamsTmp.set("status", filter.status.value);
      setSearchParams(`&${searchParamsTmp.toString()}`)
    }
    if (filter.billing_entity) {
      searchParamsTmp.set("billing_entity", filter.billing_entity.company_name);
      setSearchParams(`&${searchParamsTmp.toString()}`)
    }
    if (filter.start_date) {
      searchParamsTmp.set("start_date", filter.start_date);
      setSearchParams(`&${searchParamsTmp.toString()}`)
    }
    if (filter.end_date) {
      searchParamsTmp.set("end_date", filter.end_date);
      setSearchParams(`&${searchParamsTmp.toString()}`)
    }
    if (!filter.contractor && !filter.contract && !filter.status && !filter.billing_entity && !filter.start_date && !filter.end_date) {
      setSearchParams('')
    }
  }, [filter])

  useEffect(() => {
    const defaultUrl = new URLSearchParams()
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        defaultUrl.set(key, queryParamsValue[key])
      }
    })
    history.push({
      pathname: '/billing',
      search: defaultUrl.toString()
    })
  }, [filter])

  return (
    <PageContainer>
      <BillingContainer>
        <InfosContainer>
          <Paper elevation={1}>
            <TitleContainer>
              <Text variant="h5" marginBottom
                    color="primary">{totalInvoices && totalInvoices} {totalInvoices && totalInvoices > 1 ? 'Factures' : 'Facture'}</Text>
              <CustomLink to={'/billing/new-bill'}>
                <ButtonCustom
                  startIcon={<AddIcon/>}
                  background="blue"
                  height="40"
                  label="Nouvelle facture"
                />
              </CustomLink>
            </TitleContainer>
            <Spacer medium/>
            <ContentContainer>
              <FilterContainer>
                <SelectText
                  label="Mandataire"
                  width="300px"
                  littlePadding
                  value={filter?.contractor}
                  onChange={(_, value) => {
                    setQueryParamsValue(old => ({...old, contractor: value?.value ?? null}))
                    setFilter(oldFilter => ({...oldFilter, contractor: value}))
                  }}
                  optionKey={'label'}
                  options={contractorOptions}
                />
                <SelectText
                  label="Mandat"
                  width="300px"
                  littlePadding
                  value={filter?.contract}
                  onChange={(_, value) => {
                    setContractName(value)
                    setQueryParamsValue(old => ({...old, contract: value?.contract_name ?? null, billing_entity: null}))
                    setFilter(oldFilter => ({...oldFilter, contract: value, billing_entity: null}))
                  }}
                  optionKey={'contract_name'}
                  options={data}
                />
                <SelectText
                  label="SPV"
                  width="300px"
                  value={filter?.billing_entity}
                  onChange={(_, value) => {
                    setQueryParamsValue(old => ({...old, billing_entity: value?.company_name ?? null}))
                    setFilter(oldFilter => ({...oldFilter, billing_entity: value}))
                  }}
                  littlePadding
                  options={contractName?.billing_entity}
                  optionKey={'company_name'}
                />
                <SelectText
                  label="Statut"
                  width="300px"
                  value={filter?.status}
                  onChange={(_, value) => {
                    setQueryParamsValue(old => ({...old, status: value?.value ?? null}))
                    setFilter(oldFilter => ({...oldFilter, status: value}))
                  }}
                  littlePadding
                  options={status}
                  optionKey={'label'}
                />
              </FilterContainer>
              <FilterContainer>
                <CalendarCustom
                  label="Date de début"
                  width="300px"
                  dateDefault={filter?.start_date}
                  onChange={(e) => {
                    setQueryParamsValue(old => ({...old, start_date: e ? new Date(e).toDateString() : null}))
                    setFilter(oldFilter => ({...oldFilter, start_date: e ? new Date(e).toDateString() : null}))
                  }}
                  littlePadding
                />
                <CalendarCustom
                  label="Date de fin"
                  width="300px"
                  dateDefault={filter?.end_date}
                  onChange={(e) => {
                    setQueryParamsValue(old => ({...old, end_date: e ? new Date(e).toDateString() : null}))
                    setFilter(oldFilter => ({...oldFilter, end_date: e ? new Date(e).toDateString() : null}))
                  }}
                  littlePadding
                />
              </FilterContainer>
              <ButtonCustom
                onClick={() => setFilter({
                  contractor: null,
                  contract: null,
                  status: null,
                  billing_entity: null,
                  start_date: null,
                  end_date: null
                })}
                disabled={!filter.contractor && !filter.contract && !filter.status && !filter.billing_entity && !filter.start_date && !filter.end_date}
                background="orange"
                height="30"
                startIcon={<FilterListOffIcon/>}
                label="Réinitialiser les filtres"
              />
              <ButtonContainer>
                <ButtonCustom
                  background="dark"
                  disabled={select.length === 0}
                  startIcon={<GetAppIcon/>}
                  label="Télécharger les factures"
                  onClick={async () => {
                    const files = rows.filter(row => select.includes(row.id))
                    let zip = new JSZip()
                    for (const file of files) {
                      const response = await axios(`${file.ref.url}`, {
                        method: 'GET',
                        responseType: 'blob'
                      })
                      const data = new Blob(
                        [response.data],
                        {type: 'application/pdf'})

                      zip.file(`${file.ref.invoice_number}.pdf`, data)
                    }
                    zip.generateAsync({type: 'blob'})
                      .then(function (content) {
                        saveAs(content, 'factures.zip')
                      })
                  }}
                />
                <ButtonCustom
                  startIcon={<ListIcon/>}
                  background="green"
                  disabled={select.length === 0}
                  onClick={() => downloadExcel(DataExcel(rows.filter(row => select.includes(row.id))))}
                  label="Export Excel"
                />
              </ButtonContainer>
              {!isLoading ?
                <div style={{height: 900, width: '100%'}}>
                  <DataGrid
                    checkboxSelection
                    onSelectionModelChange={(newSelection) => {
                      setSelection(newSelection)
                    }}
                    rows={rows}
                    columns={columns}
                    paginations
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    rowCount={totalInvoices}
                    paginationMode="server"
                    onPageChange={(page) => {
                      setPage(page)
                    }}
                    loading={isLoading}
                  />
                </div>
                :
                <Loader/>
              }
            </ContentContainer>
          </Paper>
        </InfosContainer>
      </BillingContainer>
    </PageContainer>
  )
}

const CustomLink = styled(Link)`
  text-decoration: none !important;
`

const BillingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const InfosContainer = styled.div`
  width: 100%;
  margin: 20px 0;
`

const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 30px 50px 30px;
  justify-content: flex-start;
`

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const ButtonContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  height: 60px;
  display: flex;
  margin-top: 20px;
`

const TextTab = styled.p`
  font-size: 12px;
  font-weight: bold;
`


const displayStatus = (option) => {
  switch (option) {
    case status[0].value :
      return <ChipCustom background="green" label={status[0].label}/>
    case status[1].value :
      return <ChipCustom background="red" label={status[1].label}/>
    case status[2].value :
      return <ChipCustom background="yellow" label={status[2].label}/>
    case status[3].value :
      return <ChipCustom label={status[3].label}/>
    case status[4].value :
      return <ChipCustom background="orange" label={status[4].label}/>
    case status[5].value:
      return <ChipCustom background="blue" label={status[5].label}/>
    case status[6].value:
      return <ChipCustom background="blue" label={status[6].label}/>
    default :
      return <ChipCustom label="Défaut"/>
  }
}

const checkCurrency = (currency) => {
  switch (currency) {
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    default:
      return '€'
  }
}

const checkContractor = (contractor) => {
  switch (contractor) {
    case 'RED PARKS MANAGEMENT' :
      return 'RPM'
    case 'TELAMON' :
      return 'TL'
    case 'TELAMON ASSET MANAGEMENT' :
      return 'TAM'
    default :
      return '...'
  }
}

const createRow = (data) => {
  return data ? data.map((elem) => ({
    id: elem.id,
    ref: {url: elem.url, invoice_number: elem.invoice_number},
    contractor: checkContractor(elem.contractor.company_name),
    contract_name: elem.contract.contract_name,
    spv: elem.billing_entity.company_name,
    status: elem.status,
    amount_ht: useDevise(checkCurrency(elem.currency), elem.total_without_taxes),
    amount_ttc: useDevise(checkCurrency(elem.currency), elem.total_with_taxes),
    creation_date: format(new Date(elem.invoice_date), 'dd/MM/yyyy'),
    cashed: useDevise(checkCurrency(elem.currency), elem.amount_total_paid ? elem.amount_total_paid : 0),
    settlement_date: elem.amount_total_paid === "0" ? '...' : format(new Date(elem.payment_date), 'dd/MM/yyyy'),
    cross_checking: 'x'
  })) : []
}

const downloadExcel = (data) => {
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('Facture')
  const header = ['Référence', 'Mandataire', 'Mandat', 'Entité de facturation', 'Statut', 'Montant H.T.', 'Montant T.T.C.', 'Date de facturation', 'Montant Réglé', 'Date de règlement', 'Auto']
  worksheet.getRow(3).values = header
  const fName = 'Facture'

  worksheet.getRow(1).font = {
    size: 16,
    bold: true,
    color: {argb: 'FFFFFFFF'}
  }

  worksheet.getRow(3).font = {
    size: 14,
    bold: true
  }

  worksheet.getRow(3).alignment = {vertical: 'top', horizontal: 'left'}

  worksheet.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: '2C3C93'}
  }

  worksheet.columns = [
    {width: 13},
    {width: 15},
    {width: 13},
    {width: 25},
    {width: 12},
    {width: 15},
    {width: 15},
    {width: 20},
    {width: 17},
    {width: 20},
    {width: 7}
  ]
  for (const x1 of data) {
    const x2 = Object.keys(x1)
    const temp = []
    for (const y of x2) {
      temp.push(x1[y])
    }
    worksheet.addRow(temp)
  }

  //worksheet.getColumn(6).numFmt = '#.##0" €"'
  //worksheet.getColumn(7).numFmt = '#.##0" €"'
  //worksheet.getColumn(9).numFmt = '#.##0" €"'

  worksheet.mergeCells('A1', 'K1')
  worksheet.getCell('A1').value = 'Liste factures'

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx')
  })
}

export {
  BillingContent
}
