import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import {fr} from 'date-fns/locale'
import {ItemsContainer} from './cssInputsCommon'
import {FormControl} from '@material-ui/core'

function Calendar({
                    label,
                    width,
                    disabled,
                    littlePadding = false,
                    onBlur,
                    onChange,
                    error,
                    defaultValue,
                    id,
                    required
                  }) {
  const [selectedDate, setSelectedDate] = useState(defaultValue ? new Date(defaultValue) : null)

  const handleDateChange = (date) => {
    onChange(date)
    setSelectedDate(date)
  }

  return (
    <ItemsContainer littlePadding={littlePadding} width={width}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
        <Grid container justify="space-around">
          <FormControl fullWidth id={id}>
            <KeyboardDatePicker
              disableToolbar
              variant="dialog"
              disabled={disabled}
              cancelLabel='Annuler'
              onBlur={onBlur}
              defaultValue={defaultValue}
              format="dd/MM/yyyy"
              maxDateMessage="La date ne doit pas être postérieure à la date maximale"
              minDateMessage="La date ne doit pas être antérieure à la date minimale"
              margin="normal"
              error={error}
              InputProps={{
                inputProps: {
                  required: required
                }
              }}
              label={label}
              value={selectedDate}
              onChange={(e) => handleDateChange(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </FormControl>
        </Grid>
      </MuiPickersUtilsProvider>
    </ItemsContainer>
  )
}

export {
  Calendar
}
