import React, {useEffect, useRef, useState} from 'react'
import 'date-fns'
import {isValid} from 'date-fns'
import {FormControl, Grid, TextField} from "@mui/material";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import {ItemsContainer} from "./cssInputsCommon";

function CalendarCustom({
                          label,
                          disabled,
                          onChange,
                          onError,
                          dateDefault,
                          littlePadding = false,
                          name,
                          id,
                          variant = 'standard',
                          size,
                          maxDate,
                          width,
                        }) {
  const ref = useRef(null)

  const [selectedDate, setSelectedDate] = useState(isValid(new Date(dateDefault)) ? dateDefault : null)

  useEffect(() => {
    setSelectedDate(dateDefault)
  }, [dateDefault])

  return (
    <ItemsContainer littlePadding={littlePadding} width={width}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <Grid container justify="space-around">
          <FormControl fullWidth color={"primary"}>
            <DesktopDatePicker
              label={label}
              onError={onError}
              disabled={disabled}
              name={name}
              maxDate={maxDate}
              id={id}
              inputRef={ref}
              value={selectedDate}
              renderInput={(params) => <TextField size={size} color={"primary"} label={label}
                                                  variant={variant} {...params} />}
              onChange={(e) => {
                setSelectedDate(e)
                onChange(e)
              }}
            />
          </FormControl>
        </Grid>
      </LocalizationProvider>
    </ItemsContainer>
  )
}

export {
  CalendarCustom
}
