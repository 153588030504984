import React from 'react'
import styled from 'styled-components'
import {useMutation, useQueryClient} from 'react-query'
import {useSuppliers} from '../../../hooks/suppliers.hook'
import {DropzoneDialog} from "material-ui-dropzone";

function ProvidersForm({open, toggle, setSuccessMessage, setErrorMessage}) {
  const queryClient = useQueryClient()
  const {uploadFiles} = useSuppliers({})

  const {mutate} = useMutation("providers", (formData) => uploadFiles(formData), {
    onSuccess: () => {
      toggle(false)
      setSuccessMessage("Fichier importé avec succès")
    },
    onError: () => {
      toggle(false)
      setErrorMessage("Une erreur est survenue !")
    },
    onSettled: () => {
      queryClient.invalidateQueries('supplierFile').then(r => r)
    }
  })

  const handleSave = (files) => {
    const data = new FormData()
    files.forEach(file => {
      data.append(file.name, file)
    })
    mutate(data)
  }

  return (
    <ContentContainer>
      <DropzoneDialog
        open={open}
        onSave={(e) => handleSave(e)}
        showPreviews={true}
        maxFileSize={5000000}
        dialogTitle='Importer des fichiers'
        cancelButtonText='Annuler'
        submitButtonText='Valider'
        onClose={() => toggle(false)}
        previewText="Aperçu :"
        getFileAddedMessage={() => 'Le fichier a été ajouté avec succès'}
        getFileRemovedMessage={() => 'Le fichier a été supprimé'}
        getDropRejectMessage={() => 'Le fichier a été rejeté..'}
        getFileLimitExceedMessage={() => 'Votre fichier dépasse la taille limite autorisée'}
        dropzoneText="Glissez et déposez vos fichiers dans la zone"
      />
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 30px 50px 30px;
  justify-content: flex-start;
`

export {
  ProvidersForm
}
