import React from 'react'
import {OverlapContent} from "../components/contentPages/overlap";

function Overlap() {
  return (
    <OverlapContent/>
  )
}

export {
  Overlap
}
