import React, {useState} from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import {BottomNavigationMenu, PersonalInfosInMenu, TitleHeaderDrawer, TopNavigationMenu} from './drawerMenuOptions'
import PeopleIcon from "@material-ui/icons/People";
import DescriptionIcon from "@material-ui/icons/Description";
import TodayIcon from "@material-ui/icons/Today";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {MergeType} from "@material-ui/icons";
import EqualizerIcon from "@material-ui/icons/Equalizer";

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}))

function DrawerLayout({children, open, setOpen}) {

  const [pageSelected, setPageSelected] = useState('/')

  const classes = useStyles()
  const theme = useTheme()
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const nav = [{
    text: 'Mandats',
    icon: <PeopleIcon/>,
    link: '/'
  }, {
    text: 'Facturation',
    icon: <DescriptionIcon/>,
    link: '/billing'
  }, {
    text: 'Rappels',
    icon: <TodayIcon/>,
    link: '/reminders'
  }, {
    text: 'Fournisseurs',
    icon: <AccountBalanceIcon/>,
    link: '/providers'
  }, {
    text: 'Recoupement',
    icon: <MergeType/>,
    link: '/overlap'
  }, {
    text: 'Statistiques',
    icon: <EqualizerIcon/>,
    link: '/statistics'
  }]

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon/>
          </IconButton>
          <TitleHeaderDrawer pageSelected={pageSelected}/>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <Divider/>
          {open &&
          <PersonalInfosInMenu/>
          }
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
          </IconButton>
        </div>
        <Divider/>
        <ListContainer>
          <List>
            <TopNavigationMenu nav={nav} setPageSelected={setPageSelected}/>
          </List>
          <List>
            <Divider/>
            <BottomNavigationMenu/>
          </List>
        </ListContainer>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar}/>
        <ChildrenContainer>
          {children}
        </ChildrenContainer>
      </main>
    </div>
  )
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
  .MuiListItem-root.Mui-selected {
    background: white;
  }
`

const ChildrenContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export default DrawerLayout
