import React, { useEffect } from 'react'
import styled from 'styled-components'
import { LogoPanhard, PageContainer } from '../../layout/drawerMenuOptions'
import { Spacer } from '../../common/spacer'
import { InputText } from '../../common/inputs/inputText'
import { ButtonCustom } from '../../common/button'
import DoneIcon from '@material-ui/icons/Done'
import { useForm } from 'react-hook-form'
import { Paper } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Text } from '../../common/text'

function PasswordRecoveryContent() {
	const {register, handleSubmit, setValue} = useForm()
	const onSubmit = (form) => {
		console.log(form)
	}

	const handleChange = (e, name) => {
		setValue(name, e)
	}

	useEffect(() => {
		register('mail')
	}, [register])

	return (
		<Container>
			<PageContainer>
				<LoginContainer>
					<TopPage>
						<LogoPanhard maxWidth={'80px'}/>
					</TopPage>
					<ContentPage>
						<Paper elevation={1}>
							<FormContent onSubmit={handleSubmit(onSubmit)}>
								<InputText
									width="350px"
									required
									type="email"
									onChange={(e) => handleChange(e.target.value, 'mail')}
									label="Adresse e-mail"
								/>
								<ButtonCustom
									type="submit"
									width="350px"
									height="40"
									startIcon={<DoneIcon/>}
									background="blue"
									label="Valider"
								/>
								<Spacer medium/>
								<Link to='/'><Text variant="body2" color="primary">Revenir sur la page d'accueil</Text></Link>
								<Spacer medium/>
								{true && <Text variant="subtitle2" color="error">L'adresse e-mail est invalide !</Text>}
							</FormContent>
						</Paper>
					</ContentPage>
				</LoginContainer>
			</PageContainer>
		</Container>
	)
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #fafafa;
  justify-content: center;
`

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
`

const TopPage = styled.div`
  width: 100%;
  height: 25vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ContentPage = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FormContent = styled.form`
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export {
	PasswordRecoveryContent
}