import React from 'react'
import {ButtonCustom} from '../../common/button'
import {SelectText} from '../../common/inputs/inputSelectWithText'
import {Calendar} from '../../common/inputs/calendar'
import {temporality} from '../../../data/data'
import {InputText} from '../../common/inputs/inputText'
import styled from 'styled-components'
import {theme} from '../../../config/theme'
import {useMutation, useQueryClient} from 'react-query'
import {useContract} from '../../../hooks/home.hook'
import {Controller, useForm} from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {IconButton} from "@mui/material";

function UpdateReminders({dataAll, setOpen, data}) {
  const queryClient = useQueryClient()
  const {updateReminders} = useContract()

  const {mutate} = useMutation(contract => updateReminders(contract), {
    onSettled: () => {
      queryClient.invalidateQueries('getReminders')
    }
  })

  const {handleSubmit, setValue, control, getValues} = useForm({
    defaultValues: {
      title: data?.title,
      contract: data?.contract,
      start_date: data?.start_date,
      timeframe: temporality.find((e) => data?.timeframe === e.value),
      email_address: data?.email_address,
      billing_entity: data?.billing_entity
    }
  })

  const onSubmit = (form) => {
    const formValid = {
      ...form,
      contract: {connect: {id: form.contract.id}},
      billing_entity: {set: form.billing_entity.map(e => ({id: e.id}))},
      timeframe: form.timeframe.value
    }

    mutate({
      contractId: data.id, form: formValid
    })

    setOpen(false)
  }

  return (
    <ContentContainer>
      <AddRowContainer>
        <ButtonCloseContainer>
          <IconButton size="small" onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </ButtonCloseContainer>
        <FormContent onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='title'
            control={control}
            render={({value, onChange, ref}) => (
              <InputText
                required
                width="300px"
                inputRef={ref}
                onChange={onChange}
                value={value}
                label="Nom du rappel"
              />
            )}
          />
          <Controller
            name='contract'
            control={control}
            render={({value, onChange, ref}) => (
              <SelectText
                id="contract"
                label="Mandat"
                value={value}
                width="300px"
                onChange={(_, value) => {
                  setValue('billing_entity', [])
                  onChange(value)
                }}
                options={dataAll}
                inputRef={ref}
                optionKey={'contract_name'}
              />
            )}
          />
          <Controller
            name='start_date'
            control={control}
            render={({value, onChange}) => (
              <Calendar
                label="Date de début"
                onChange={onChange}
                defaultValue={value}
              />
            )}
          />
          <Controller
            name='timeframe'
            control={control}
            render={({value, onChange, ref}) => (
              <SelectText
                label="Temporalité"
                options={temporality}
                required
                inputRef={ref}
                value={value}
                onChange={(e, value) => {
                  onChange(value)
                }}
                optionKey={'label'}
              />
            )}
          />
          <Controller
            name='email_address'
            control={control}
            render={({value, onChange, ref}) => (
              <InputText
                type="email"
                width="300px"
                required
                inputRef={ref}
                onChange={onChange}
                value={value}
                label="E-mail"
              />
            )}
          />
          <ContentContainer>
            <Controller
              name='billing_entity'
              control={control}
              render={({value, onChange, ref}) => (
                <SelectText
                  label="SPV"
                  width="800px"
                  multiple
                  onChange={(e, value) => {
                    onChange(value)
                  }}
                  value={value}
                  inputRef={ref}
                  options={dataAll.find((e) => e.contract_name === getValues('contract')?.contract_name)?.billing_entity}
                  optionKey={'company_name'}
                />
              )}
            />
          </ContentContainer>
          <ButtonSubmitContainer>
            <ButtonCustom
              type="submit"
              startIcon={<DoneIcon/>}
              background="blue"
              label="Modifier rappel"
            />
          </ButtonSubmitContainer>
        </FormContent>
      </AddRowContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 30px 50px 30px;
  justify-content: center;
  align-items: center;
`

const AddRowContainer = styled.div`
  width: 90%;
  height: auto;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid ${theme.grey};
  position: relative;
`

const FormContent = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const ButtonCloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonSubmitContainer = styled.div`
  position: absolute;
  padding: 30px 0;
  bottom: -20px;
  right: 5px;
`

export {
  UpdateReminders
}
