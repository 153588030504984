import {authenticatedHeaders, axios} from '../config/axios'

async function uploadFiles(data) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/merger/csv`, data, {headers: {...authenticatedHeaders}})
}

function useOverlap() {
  return {
    uploadFiles,
  }
}

export {
  useOverlap,
}
