import { createKey } from '../../common/utils'
import { Chip } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { useMutation, useQueryClient } from 'react-query'
import { useContract } from '../../../hooks/home.hook'

function ListPLaces({data}) {
	const queryClient = useQueryClient()
	const {deleteContract} = useContract()
	const {mutate} = useMutation(contract => deleteContract(contract), {
		onSettled: () => {
			queryClient.invalidateQueries('getContractWithId')
		}
	})

	const handleDelete = (e) => {
		mutate({contractId: e})
	}

	return (
		<ChipContainer>
			{data.places.map((e, i) => {
				return (
					<div key={createKey(i)} style={{
						margin: '4px 3px'
					}}>
						<Chip onDelete={() => handleDelete(e.id)} label={e.name} size="medium"/>
					</div>
				)
			})}
		</ChipContainer>
	)
}

const ChipContainer = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

export { ListPLaces }