import React from 'react'
import {ItemsContainer} from './cssInputsCommon'
import {InputAdornment, TextField} from "@mui/material";

function InputText({
                     type = 'text',
                     label,
                     onBlur,
                     size,
                     onChange,
                     name,
                     disabled = false,
                     error = false,
                     width,
                     value,
                     icon,
                     littlePadding = false,
                     min,
                     required,
                     inputRef,
                     id,
                     max,
                     rowsMax,
                     multiline,
                     defaultValue,
                     maxlength
                   }) {

  return (
    <ItemsContainer littlePadding={littlePadding} width={width}>
      <TextField
        label={label}
        disabled={disabled}
        id={id}
        onBlur={onBlur}
        rowsMax={rowsMax}
        onChange={onChange}
        inputRef={inputRef}
        value={value}
        size={size}
        variant="outlined"
        defaultValue={defaultValue}
        type={type}
        required={required}
        multiline={multiline}
        name={name}
        error={error}
        InputProps={{
          inputProps: {
            min: min,
            max: max,
            maxlength: maxlength
          },
          startAdornment: (
            icon && <InputAdornment position="start" children={icon}/>
          )
        }}
        fullWidth
      />
    </ItemsContainer>
  )
}


export {
  InputText
}
