import React from 'react'
import {MandateCard} from './mandateCard'
import {PageContainer} from '../../layout/drawerMenuOptions'

function HomeContent({data}) {
  return (
    <PageContainer style={{flexWrap: 'wrap'}}>
      <MandateCard data={data}/>
    </PageContainer>
  )
}

export {
  HomeContent
}
