import React, {useState} from 'react'
import styled from 'styled-components'
import {Text} from '../../common/text'
import {BillingEntitiesForm} from './billingEntitiesForm'
import AddIcon from '@mui/icons-material/Add';
import {ButtonCustom} from '../../common/button'
import {HeadersItems} from './headersItems'
import {createKey, cropAndAddDots} from '../../common/utils'
import CreateIcon from '@mui/icons-material/Create';
import {UpdateFormBillingEntities} from './updateFormBillingEntities'
import {theme} from '../../../config/theme'
import {useMutation, useQueryClient} from 'react-query'
import DeleteIcon from '@mui/icons-material/Delete';
import {useContract} from '../../../hooks/home.hook'
import {Chip, IconButton} from "@mui/material";

function BillingEntities({data, openDrawer}) {

  const rows = data && data.billing_entity ? data.billing_entity : []

  const {deleteBillingEntity} = useContract()
  const widthScreen = global.innerWidth

  const [update, setUpdate] = useState(null)

  const [openCreate, setOpenCreate] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)

  const toggleCreate = () => {
    setOpenCreate(!openCreate)
  }

  const toggleUpdate = (data = null) => {
    setOpenUpdate(!openUpdate)
    setUpdate(data)
  }

  return (
    <>
      <TitleContainer>
        <Text variant="h5" marginBottom
              color="primary">{rows.length} {rows.length > 1 ? 'Entités de facturation' : 'Entité de facturation'}</Text>
        <ButtonCustom
          onClick={toggleCreate}
          background="blue"
          height="40"
          startIcon={<AddIcon/>}
          label="Ajouter une entité de facturation"
        />
      </TitleContainer>
      <ContentContainer>
        <TabContainer widthScreen={widthScreen} openDrawer={openDrawer}>
          <HeaderItemsContainer>
            <HeadersItems separator headerName="Raison sociale" width="200px"/>
            <HeadersItems separator headerName="Type" width="80px"/>
            <HeadersItems separator headerName="Adresse"/>
            <HeadersItems separator headerName="Contact" width="200px"/>
            <HeadersItems separator headerName="Siret" width="160px"/>
            <HeadersItems separator headerName="Site(s)" width="300px"/>
            <HeadersItems headerName="Modifier / Supprimer" width="110px"/>
          </HeaderItemsContainer>
          {rows.map((element, index) => {
            return (
              <RowItemsContainer key={createKey(index)}>
                <RowItem hover width="200px">
                  <Text variant="body2">{cropAndAddDots(element?.company_name, 23)}</Text>
                </RowItem>
                <RowItem hover width="80px">
                  <Text variant="body2">{cropAndAddDots(element?.company_type, 7)}</Text>
                </RowItem>
                <RowItem hover>
                  <Text variant="body2">{element?.address}</Text>
                </RowItem>
                <RowItem hover width="200px">
                  <a href={`mailto:${element?.email_contact}`}>{(element?.email_contact, 26)}</a>
                </RowItem>
                <RowItem hover width="160px">
                  <Text variant="body2">{cropAndAddDots(element?.siret, 16)}</Text>
                </RowItem>
                <RowItem hover width="300px">
                  {element && element?.places ?
                    element?.places.map((e, i) => {
                      return (
                        <div key={createKey(i)} style={{
                          margin: '3px 2px'
                        }}>
                          <Chip label={e?.name} size="small"/>
                        </div>
                      )
                    }) : []
                  }
                </RowItem>
                <RowItem width="110px" arround>
                  <IconButton size="small" onClick={() => {
                    toggleUpdate(element)
                  }}>
                    <CreateIcon fontSize="small" color="primary"/>
                  </IconButton>
                  <DeleteRowBillingEntities element={element} deleteBillingEntity={deleteBillingEntity}/>
                </RowItem>
              </RowItemsContainer>
            )
          })}
        </TabContainer>
      </ContentContainer>
      {openCreate && <BillingEntitiesForm setOpen={(e) => setOpenCreate(e)} data={data}/>}
      {openUpdate && <UpdateFormBillingEntities data={update} dataAll={data} setOpen={(e) => setOpenUpdate(e)}/>}
    </>
  )
}

function DeleteRowBillingEntities({element, deleteBillingEntity}) {
  const queryCache = useQueryClient()

  const {mutate} = useMutation(contract => deleteBillingEntity(contract), {
    onSettled: () => {
      queryCache.invalidateQueries('getContractWithId')
    }
  })

  const handleDelete = (e) => {
    mutate({contractId: e})
  }

  return (
    <IconButton size="small" onClick={() => handleDelete(element.id)}>
      <DeleteIcon fontSize="small" color="error"/>
    </IconButton>
  )
}


const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px 50px 30px;
  justify-content: center;
  align-items: center;
`

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.grey};
  border-top: 1px solid ${theme.grey};
  border-left: 1px solid ${theme.grey};
  border-radius: 4px 4px 0 0;
  width: auto;
  max-width: ${props => props.widthScreen <= 1440 && props.openDrawer ? '1090px' : null};
  overflow: auto;
`

const HeaderItemsContainer = styled.div`
  display: flex;
`

const RowItemsContainer = styled.div`
  display: flex;
`

const RowItem = styled.div`
  border-bottom: 1px solid ${theme.grey};
  min-height: 52px;
  padding: 4px 16px;
  width: ${props => props.width ? props.width : '200px'};
  min-width: ${props => props.width ? props.width : '200px'};
  display: flex;
  justify-content: ${props => props.arround ? 'space-around' : 'flex-start'};
  align-items: center;
  transition: all 0.3s;
  flex-wrap: wrap;

  :hover {
    background: ${props => props.hover ? theme.lightBlue : 'none'}
  }
`

export {
  BillingEntities
}
