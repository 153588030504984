import { useQuery } from "react-query";
import { authenticatedHeaders, axios } from "../config/axios";

function UseStats(param) {
    return useQuery('getStats', async () => {
      return axios
        .get(`${process.env.REACT_APP_API_URL}/statistics/revenue/${param}`, {headers: {...authenticatedHeaders}})
        .then(res => res.data)
    })
  }

function UseExportStats(param) {
  return useQuery('getExportStats', async () => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/statistics/revenue/export/${param}`, {headers: {...authenticatedHeaders}})
      .then(res => res.data)
  })
}

export {
  UseStats,
  UseExportStats
}
