import React, {useState} from 'react'
import {PageContainer} from '../../layout/drawerMenuOptions'
import {Chip, IconButton, Paper} from '@material-ui/core'
import {Text} from '../../common/text'
import styled from 'styled-components'
import {RemindersForm} from './remindersForm'
import {HeadersItems} from '../mandate/headersItems'
import {createKey, cropAndAddDots} from '../../common/utils'
import CreateIcon from '@material-ui/icons/Create'
import {theme} from '../../../config/theme'
import {useMutation, useQueryClient} from 'react-query'
import DeleteIcon from '@material-ui/icons/Delete'
import {temporalityMapper} from '../../../data/data'
import {useContract} from '../../../hooks/home.hook'
import {format} from 'date-fns'
import {UpdateReminders} from './updateRemindersForm'
import {ButtonCustom} from '../../common/button'
import AddIcon from '@material-ui/icons/Add'

function RemindersContent({data, openDrawer}) {
  const {getReminders} = useContract()
  const {deleteReminders} = useContract()
  const rows = getReminders.data
  const widthScreen = global.innerWidth
  const [update, setUpdate] = useState(null)
  const [openCreate, setOpenCreate] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const toggleCreate = () => {
    setOpenCreate(!openCreate)
  }

  const toggleUpdate = (data = null) => {
    setOpenUpdate(!openUpdate)
    setUpdate(data)
  }

  return (
    <PageContainer>
      <RemindersContainer>
        <InfosContainer>
          <Paper elevation={1} style={{width: "100%"}}>
            <TitleContainer>
              <Text variant="h5" marginBottom
                    color="primary">{rows?.length} {rows?.length > 1 ? 'Rappels' : 'Rappel'}</Text>
              <ButtonCustom
                onClick={toggleCreate}
                background="blue"
                height="40"
                startIcon={<AddIcon/>}
                label="Nouveau rappel"
              />
            </TitleContainer>
            <ContentContainer>
              <TabContainer widthScreen={widthScreen} openDrawer={openDrawer}>
                <HeaderItemsContainer>
                  <HeadersItems separator headerName="Nom" width="130px"/>
                  <HeadersItems separator headerName="Mandat" width="130px"/>
                  <HeadersItems separator headerName="SPV" width="300px"/>
                  <HeadersItems separator headerName="Date de début" width="150px"/>
                  <HeadersItems separator headerName="Temporalité" width="110px"/>
                  <HeadersItems separator headerName="E-mail" width="230px"/>
                  <HeadersItems headerName="Modifier / Supprimer" width="110px"/>
                </HeaderItemsContainer>
                {rows && rows.map((element, index) => {
                  return (
                    <RowItemsContainer key={createKey(index)}>
                      <RowItem hover width="130px">
                        <Text variant="body2">{cropAndAddDots(element?.title, 14)}</Text>
                      </RowItem>
                      <RowItem hover width="130px">
                        <Text variant="body2">{cropAndAddDots(element?.contract.contract_name, 14)}</Text>
                      </RowItem>
                      <RowItem hover width="300px">
                        {element && element.billing_entity ?
                          element.billing_entity.map((e, i) => {
                            return (
                              <div key={createKey(i)} style={{
                                margin: '3px 2px'
                              }}>
                                <Chip label={e?.company_name} size="small"/>
                              </div>
                            )
                          }) : []
                        }
                      </RowItem>
                      <RowItem hover width="150px">
                        <Text variant="body2">{format(new Date(element?.start_date), 'dd/MM/yyyy')}</Text>
                      </RowItem>
                      <RowItem hover width="110px">
                        <Text variant="body2">{temporalityMapper[element?.timeframe] || element?.timeframe}</Text>
                      </RowItem>
                      <RowItem hover width="230px">
                        <a href={`mailto:${element?.email_address}`}>{cropAndAddDots(element?.email_address, 26)}</a>
                      </RowItem>
                      <RowItem width="110px" arround>
                        <IconButton size="small" onClick={() => {
                          toggleUpdate(element)
                        }}>
                          <CreateIcon fontSize="small" color="primary"/>
                        </IconButton>
                        <DeleteRowReminders element={element} deleteReminders={deleteReminders}/>
                      </RowItem>
                    </RowItemsContainer>
                  )
                })}
              </TabContainer>
            </ContentContainer>
            {openCreate && <RemindersForm data={data} setOpen={(e) => setOpenCreate(e)}/>}
            {openUpdate && <UpdateReminders data={update} dataAll={data} setOpen={(e) => setOpenUpdate(e)}/>}
          </Paper>
        </InfosContainer>
      </RemindersContainer>
    </PageContainer>
  )
}

function DeleteRowReminders({element, deleteReminders}) {
  const queryCache = useQueryClient()

  const {mutate} = useMutation(contract => deleteReminders(contract), {
    onSettled: () => {
      queryCache.invalidateQueries('getReminders')
    }
  })

  const handleDelete = (e) => {
    mutate({contractId: e})
  }

  return (
    <IconButton size="small" onClick={() => handleDelete(element.id)}>
      <DeleteIcon fontSize="small" color="error"/>
    </IconButton>
  )
}

const RemindersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const InfosContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 30px 50px 30px;
  justify-content: center;
  align-items: center;
`

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.grey};
  border-top: 1px solid ${theme.grey};
  border-left: 1px solid ${theme.grey};
  border-radius: 4px 4px 0 0;
  width: auto;
  max-width: ${props => props.widthScreen <= 1440 && props.openDrawer ? '1090px' : null};
  overflow: auto;
`

const HeaderItemsContainer = styled.div`
  display: flex;
`

const RowItemsContainer = styled.div`
  display: flex;
`

const RowItem = styled.div`
  border-bottom: 1px solid ${theme.grey};
  min-height: 52px;
  padding: 4px 16px;
  width: ${props => props.width ? props.width : '200px'};
  min-width: ${props => props.width ? props.width : '200px'};
  display: flex;
  justify-content: ${props => props.arround ? 'space-around' : 'flex-start'};
  align-items: center;
  transition: all 0.3s;
  flex-wrap: wrap;

  :hover {
    background: ${props => props.hover ? theme.lightBlue : 'none'}
  }
`

export {
  RemindersContent
}
