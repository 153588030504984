import { Text } from '../../common/text'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../config/theme'

function HeadersItems({separator, width, headerName}) {
	return (
		<HeaderItem width={width}>
			<Text variant="subtitle2">{headerName}</Text>
			{separator && <Separator/>}
		</HeaderItem>
	)
}

const HeaderItem = styled.div`
  width: ${props => props.width ? props.width : '200px'};
  min-width: ${props => props.width ? props.width : '200px'};
  height: 56px;
  position: relative;
  padding: 0 16px;
  display: flex;
  align-items: center;
	border-bottom: 1px solid ${theme.grey};
`

const Separator = styled.div`
  width: 2px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: ${theme.grey};
`

export {
	HeadersItems
}