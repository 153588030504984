import React from 'react'
import {NewBillContent} from '../components/contentPages/newBill'
import {useContract} from '../hooks/home.hook'

function NewBill() {

  const {getContract, getFilters} = useContract()
  const data = getContract.data
  const typeOptions = getFilters.data


  return (
    <NewBillContent data={data} typeOptions={typeOptions}/>
  )
}

export {
  NewBill
}
