import React from 'react'
import styled from 'styled-components'
import {CircularProgress} from "@mui/material";

function Loader({height}) {
	return (
		<LoaderContainer height={height}>
			<CircularProgress color="secondary" size={60}/>
		</LoaderContainer>
	)
}

const LoaderContainer = styled.div`
  width: 100%;
  height: ${props => props.height ? props.height : '150px'};
  display: flex;
  align-items: center;
  justify-content: center;
`

export {
	Loader
}
