import React from 'react'
import styled from 'styled-components'
import {Button} from "@mui/material";
import {withTheme} from "@mui/styles";

function ButtonCustom({
                        label,
                        margin = 'true',
                        background,
                        type = 'button',
                        onClick,
                        startIcon,
                        size = 'small',
                        fullWidth = false,
                        width = 'auto',
                        height,
                        disabled = false,
                      }) {
  return (
    <ButtonCustomELement
      onClick={onClick}
      type={type}
      startIcon={startIcon}
      margin={margin}
      variant='contained'
      size={size}
      background={background}
      fullWidth={fullWidth}
      width={width}
      height={height}
      disabled={disabled}
    >
      {label}
    </ButtonCustomELement>
  )
}

const ButtonCustomELement = withTheme(styled(Button)`
  background-color: ${({background, theme, disabled}) => disabled ? '#e4e4e4' : theme[background]} !important;
  color: ${props => props.disabled || props.background !== 'default' ? '#ffffff' : '#505F79'} !important;
  text-transform: inherit !important;
  margin: ${props => props.margin ? '0 5px !important' : '0'};
  width: ${props => props.width ? props.width : null} !important;
  height: ${props => props.height ? `${props.height}px` : '30px'} !important;
`)

export {
  ButtonCustom
}
