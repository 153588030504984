import React from 'react'
import { StatisticsContent } from '../components/contentPages/statistics'

function Statistics() {
  return (
    <StatisticsContent/>
  )
}

export {
  Statistics
}
