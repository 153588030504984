import React from 'react'
import {PageContainer} from '../../layout/drawerMenuOptions'
import styled from 'styled-components'
import {GeneralInformation} from './generalInformation'
import {BillingEntities} from './billingEntities'
import {DescriptionContainer} from './descriptionContainer'
import {PlacesContainer} from './placesContainer'
import {useContract} from '../../../hooks/home.hook'
import {getParamUrl} from '../../common/utils'
import {Paper} from "@mui/material";

function MandateContent({openDrawer}) {

  const {getContractWithId} = useContract(getParamUrl('id'))
  const {data, isLoading} = getContractWithId

  return (
    <PageContainer>
      {data && <MandateContainer>
        <InfosContainer>
          <Paper elevation={1}>
            <GeneralInformation data={data} isLoading={isLoading}/>
          </Paper>
        </InfosContainer>
        <InfosContainer>
          <Paper elevation={1}>
            <DescriptionContainer data={data} isLoading={isLoading}/>
          </Paper>
        </InfosContainer>
        <InfosContainer>
          <Paper elevation={1}>
            <PlacesContainer data={data} isLoading={isLoading}/>
          </Paper>
        </InfosContainer>
        <InfosContainer>
          <Paper elevation={1}>
            <BillingEntities data={data} isLoading={isLoading} openDrawer={openDrawer}/>
          </Paper>
        </InfosContainer>
      </MandateContainer>}
    </PageContainer>
  )
}

const InfosContainer = styled.div`
  width: 100%;
  margin: 20px 0;
`

const MandateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`


export {
  MandateContent
}
