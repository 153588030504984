import React from 'react'
import {HomeContent} from '../components/contentPages/home'
import {useContract} from '../hooks/home.hook'

function Home() {

  const {getContract} = useContract()
  const data = getContract.data

  return (
    <HomeContent data={data} />
  )
}

export {
  Home
}
