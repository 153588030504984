import React, {useState} from 'react'
import './App.css'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {theme} from './config/theme'
import {Reset} from 'styled-reset'
import {GlobalStyle} from './config/injectGlobal'
import {AuthenticatedApp} from './components/auth/authenticatedApp'
import styled from 'styled-components'
import {QueryClient, QueryClientProvider} from 'react-query'
import {UnAuthenticatedApp} from './components/auth/unAuthenticatedApp'
import {AuthConsumer, AuthProvider} from './hooks/auth.hook'
import {ThemeProvider} from '@mui/material/styles';

function App() {
  const [openDrawer, setOpenDrawer] = useState(false)
  const DisplayApp = ({isLoggedIn}) => {
    return isLoggedIn ? <AuthenticatedApp openDrawer={openDrawer} setOpenDrawer={(e) => setOpenDrawer(e)}/> :
      <UnAuthenticatedApp/>
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Reset/>
      <GlobalStyle/>
      <ThemeProvider theme={theme}>
        <GlobalContainer>
          <Router>
            <Switch>
              <AuthProvider>
                <AuthConsumer>
                  {({isLoggedIn}) => <DisplayApp isLoggedIn={isLoggedIn}/>}
                </AuthConsumer>
              </AuthProvider>
            </Switch>
          </Router>
        </GlobalContainer>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const GlobalContainer = styled.div`
  width: 100%;
  height: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
`

export default App
