import React from 'react'
import { RemindersContent } from '../components/contentPages/reminders'
import { useContract } from '../hooks/home.hook'

function Reminders({openDrawer}) {

	const {getContract} = useContract()
	const data = getContract.data

	return (
		<RemindersContent openDrawer={openDrawer} data={data}/>
	)
}

export {
	Reminders
}
