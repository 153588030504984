import {useMutation, useQueryClient} from 'react-query'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {getParamUrl} from '../../common/utils'
import {ButtonCustom} from '../../common/button'
import AddIcon from '@material-ui/icons/Add'
import DoneIcon from '@material-ui/icons/Done'
import {Text} from '../../common/text'
import CancelIcon from '@material-ui/icons/Cancel'
import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/Delete'
import {theme} from '../../../config/theme'
import {useInvoices} from '../../../hooks/invoices.hook'
import CircularProgress from '@material-ui/core/CircularProgress'

function ButtonSaveInvoice({fields, handleSubmit, getIsLoading, forUpdate = false}) {
  const queryClient = useQueryClient()
  const {createInvoices, updateInvoices, deleteInvoices, validateInvoices} = useInvoices()
  const [openValidator, setOpenValidator] = useState(false)

  const toggleValidator = () => {
    setOpenValidator(!openValidator)
  }

  let history = useHistory()
  const id = getParamUrl('id')


  const {mutate, isLoading} = useMutation(invoices => forUpdate ? updateInvoices({
    invoicesId: id,
    form: invoices
  }) : createInvoices(invoices), {
    onSettled: () => {
      if (forUpdate) {
        queryClient.invalidateQueries('getInvoicesWithId')
        getIsLoading(false)
      } else {
        queryClient.invalidateQueries('getInvoices')
      }
    },
    onSuccess: (response) => {
      if (!forUpdate) {
        history.push(`/billing/bill?id=${response.id}`)
      }
    }
  })

  useEffect(() => {
    getIsLoading(isLoading)
  }, [isLoading])

  const onSubmit = (form) => {
    const formValid = {
      contractorId: form.contract_name.contractorId,
      contractId: form.contract_name.id,
      invoice_date: form.billing_date,
      due_date: form.due_date,
      description: form.general_description,
      currency: form.devise.value,
      analytic_codeId: form.analytic_code.id,
      payment_method: form.payment.value,
      billing_entityId: form.billing_entity.id,
      amount_initial_name: form.amount_initial_name,
      fees_name: form.fees_name,
      total_without_taxes: form.total_without_taxes.toString(),
      text_total_with_taxes: form.text_total_with_taxes,
      total_with_taxes: form.total_with_taxes.toString(),
      articles: form.listFees.map((e) => (
        {
          type: e.type.value,
          description: e.description,
          fee: e.fee.toString(),
          taxe: e.taxe.toString(),
          amount_initial: e.rent.toString(),
          amount_without_taxes: e.amount_without_taxes.toString(),
          amount_with_taxes: e.amount_with_taxes.toString()
        }
      ))
    }
    mutate({form: formValid})
  }

  return isLoading ? null :
    <>
      {openValidator && <Validator setOpenValidator={setOpenValidator} validateInvoices={validateInvoices} id={id}/>}
      {forUpdate && <DeleteInvoices deleteInvoices={deleteInvoices} id={id}/>}
      <ButtonCustom
        width="150px"
        onClick={handleSubmit(onSubmit)}
        disabled={!fields.length > 0}
        startIcon={<AddIcon/>}
        background="blue"
        label={forUpdate ? 'Mettre à jour' : 'Enregistrer'}
      />
      {forUpdate &&
      <ButtonCustom
        width="150px"
        disabled={!fields.length > 0}
        onClick={() => toggleValidator()}
        startIcon={<DoneIcon/>}
        background="green"
        label="Valider"
      />}
    </>
}

function DeleteInvoices({deleteInvoices, id}) {
  let history = useHistory()
  const queryCache = useQueryClient()

  const {mutate, isLoading} = useMutation(invoicesId => deleteInvoices(invoicesId), {
    onSettled: () => {
      queryCache.invalidateQueries('getInvoices')
    },
    onSuccess: () => {
      history.push('/billing')
    }
  })

  const handleDelete = (e) => {
    mutate({invoicesId: e})
  }
  return (
    <>
      {isLoading ?
        <div style={{margin: '0 20px 0 20px'}}>
          <CircularProgress color="secondary" size={20}/>
        </div> :
        <ButtonCustom
          width="150px"
          onClick={() => handleDelete(id)}
          startIcon={<DeleteIcon/>}
          background="red"
          label="Supprimer"
        />
      }
    </>
  )
}

function Validator({setOpenValidator, validateInvoices, id}) {
  let history = useHistory()
  const queryCache = useQueryClient()

  const {mutate, isLoading} = useMutation(invoicesId => validateInvoices(invoicesId), {
    onSettled: () => {
      queryCache.invalidateQueries('getInvoices')
    },
    onSuccess: () => {
      history.push('/billing')
    }
  })

  const handleValidate = (e) => {
    mutate({invoicesId: e})
  }

  return (
    <ConfirmBackground>
      <ConfirmContainer>
        <TextConfimContainer>
          <Text variant="subtitle2" marginBottom color="error">
            Vous êtes sur le point de transformer ce brouillon en facture officielle.
          </Text>
          <Text variant="subtitle2" marginBottom color="secondary">
            Cette action n'est pas réversible. Vous ne pourrez plus modifier ou effacer cette facture. Confirmez-vous
            cette action ?
          </Text>
        </TextConfimContainer>
        <ButtonConfirmContainer>
          <ButtonCustom
            width="150px"
            onClick={() => {
              setOpenValidator(false)
            }}
            startIcon={<CancelIcon/>}
            background="red"
            label="Annuler"
          />

          {isLoading ?
            <div style={{margin: '0 20px 0 20px'}}>
              <CircularProgress color="secondary" size={20}/>
            </div> :
            <ButtonCustom
              width="150px"
              onClick={() => {
                handleValidate(id)
              }}
              startIcon={<DoneIcon/>}
              background="green"
              label="Valider"
            />
          }
        </ButtonConfirmContainer>
      </ConfirmContainer>
    </ConfirmBackground>
  )
}


const ConfirmBackground = styled.div`
  position: fixed;
  background: rgba(22, 43, 76, 0.9);
  z-index: 9999;
  width: 100%;
  height: 100%;
  transform: translate(-50%);
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ConfirmContainer = styled.div`
  width: 600px;
  height: 200px;
  border-radius: 4px;
  background: ${theme.white};
`

const TextConfimContainer = styled.div`
  height: 80%;
  display: flex;
  align-items: flex-start;
  padding: 30px;
  justify-content: center;
  flex-direction: column;
`
const ButtonConfirmContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20%;
  padding: 10px;
  justify-content: flex-end;
  align-items: flex-end;
`


export {
  ButtonSaveInvoice,
  DeleteInvoices
}
