import React from 'react'
import { PasswordRecoveryContent } from '../components/contentPages/password-recovery'

function PasswordRecovery() {
	return (
		<PasswordRecoveryContent/>
	)
}

export {
	PasswordRecovery
}