import React from 'react'
import styled from 'styled-components'
import {Typography} from "@mui/material";

function Text({children, align, color, customcolor, variant, component, marginBottom}) {
  return (
    <TextCustom align={align} color={color} customcolor={customcolor} component={component} paragraph={marginBottom}
                variant={variant}>
      {children}
    </TextCustom>
  )
}

const TextCustom = styled(Typography)`
  color: ${props => props.customcolor} !important;
`

export {
  Text
}
