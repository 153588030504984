import React, {
	useEffect, useState, useContext, useMemo, useCallback
} from 'react'
import { attachTokenToAxiosRequest } from '../config/axios'

const AuthContext = React.createContext()

function useAuth() {
	const context = useContext(AuthContext)
	if (!context) {
		throw new Error('useAuth must be used within a AuthProvider')
	}
	const { isLoggedIn, setIsLoggedIn, logout } = context

	const disconnect = useCallback(logout, [])

	return {
		isLoggedIn, setIsLoggedIn, disconnect
	}
}

function AuthProvider(props) {
	const [isLoggedIn, setIsLoggedIn] = useState(false)

	async function checkTokenInStorage() {
		return localStorage.getItem('token')
	}

	const logout = async () => {
		localStorage.clear()
		attachTokenToAxiosRequest(null)
		setIsLoggedIn(false)
		window.location = '/'
	}

	useEffect(() => {
		checkTokenInStorage()
			.then((token) => {
				if (token) {
					return setIsLoggedIn(true)
				}
				return setIsLoggedIn(false)
			})
	}, [isLoggedIn])

	const value = useMemo(() => ({ isLoggedIn, setIsLoggedIn, logout }), [isLoggedIn])
	return <AuthContext.Provider value={value} {...props} />
}

function AuthConsumer({ children }) {
	return (
		<AuthContext.Consumer>
			{children}
		</AuthContext.Consumer>
	)
}

export {
	AuthProvider,
	AuthConsumer,
	AuthContext,
	useAuth
}
