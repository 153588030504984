import React, {useEffect, useState} from 'react'
import {BillingContent} from '../components/contentPages/billing'
import {useContract} from '../hooks/home.hook'
import {usePaginateInvoices} from '../hooks/invoices.hook'

function Billing() {
  const [page, setPage] = useState('0')
  const [invoices, setInvoices] = useState([])
  const [searchParams, setSearchParams] = useState('')

  const {getContract} = useContract()
  const datas = getContract.data
  const {getInvoices} = usePaginateInvoices({page, search: searchParams})

  const {
    isLoading,
    data,
    isPreviousData,
    isFetching
  } = getInvoices

  useEffect(() => {
    if (data && !isPreviousData) {
      const invoicesTmp = [...data.invoices]
      setInvoices(invoicesTmp)
    }
  }, [data, isFetching])

  if (!data) {
    return null
  }

  return (
    <BillingContent data={datas}
                    dataInvoices={invoices}
                    isLoading={isLoading}
                    setPage={setPage}
                    totalInvoices={data?.total}
                    setSearchParams={(e) => setSearchParams(e)}/>
  )
}

export {
  Billing
}
