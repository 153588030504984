import React from 'react'
import {LoginContent} from '../components/contentPages/login'

function Login() {
  return (
    <LoginContent/>
  )
}

export {
  Login
}
