import React from 'react'
import {Home} from '../../pages/home'
import {Route} from 'react-router-dom'
import {Billing} from '../../pages/billing'
import {Reminders} from '../../pages/reminders'
import {Statistics} from '../../pages/statistics'
import {Mandate} from '../../pages/mandate'
import {NewBill} from '../../pages/newBill'
import DrawerLayout from '../layout'
import {Providers} from '../../pages/providers'
import {Bill} from '../../pages/bill'
import {Overlap} from "../../pages/overlap";

function AuthenticatedApp({openDrawer, setOpenDrawer}) {
  return (
    <DrawerLayout open={openDrawer} setOpen={setOpenDrawer}>
      <Route exact path="/">
        <Home/>
      </Route>
      <Route exact path="/mandate">
        <Mandate openDrawer={openDrawer}/>
      </Route>
      <Route exact path="/billing">
        <Billing/>
      </Route>
      <Route exact path="/billing/new-bill">
        <NewBill/>
      </Route>
      <Route exact path="/billing/bill">
        <Bill/>
      </Route>
      <Route exact path="/reminders">
        <Reminders openDrawer={openDrawer}/>
      </Route>
      <Route exact path="/providers">
        <Providers/>
      </Route>
      <Route exact path="/overlap">
        <Overlap/>
      </Route>
      <Route exact path="/statistics">
        <Statistics/>
      </Route>
    </DrawerLayout>
  )
}

export {
  AuthenticatedApp
}
