import styled from 'styled-components'

const ItemsContainer = styled.div`
  position: relative;
  padding: ${props => props.littlePadding ? '0 5px' : '0 20px'};
  width: ${props => props.width ? props.width : '250px'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
`

export {
	ItemsContainer
}