import * as axiosInstance from 'axios'

const axios = axiosInstance.create({
	timeout: 100000,
});

const authenticatedHeaders = {
		Authorization: `Bearer ${localStorage.getItem('token')}`
}

const attachTokenToAxiosRequest = accessToken => axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (401 === error.response.status) {
		localStorage.clear()
		window.location = '/'
	} else {
		return Promise.reject(error);
	}
});

export {
	axios,
	attachTokenToAxiosRequest,
	authenticatedHeaders
}
