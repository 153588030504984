import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {Text} from '../common/text'
import {Spacer} from '../common/spacer'
import {Breadcrumbs} from '@material-ui/core'
import {Link as LinkMaterial} from '@material-ui/core'
import {useContract} from '../../hooks/home.hook'
import {getParamUrl} from '../common/utils'
import {useAuth} from '../../hooks/auth.hook'

function TitleHeaderDrawer({pageSelected}) {
  const {getContractWithId} = useContract(getParamUrl('id'))
  const {data, isLoading} = getContractWithId

  const checkTitle = () => {
    const expr = window.location.pathname || pageSelected
    switch (expr) {
      case  '/' :
        return 'Mandats'
      case  '/mandate' :
        return (
          <CustomBreadcrumbs>
            <CustomLinkMaterial color="secondary" href="/">
              <Text customcolor="white" variant="h6" color="textPrimary">Mandats</Text>
            </CustomLinkMaterial>
            <Text customcolor="white" variant="h6" color="textPrimary">{isLoading ? '...' : data.contract_name}</Text>
          </CustomBreadcrumbs>
        )
      case  '/billing' :
        return 'Facturation'
      case '/billing/new-bill':
        return (
          <CustomBreadcrumbs>
            <CustomLinkMaterial color="secondary" href="/billing">
              <Text customcolor="white" variant="h6" color="textPrimary">Facturation</Text>
            </CustomLinkMaterial>
            <Text customcolor="white" variant="h6" color="textPrimary">Nouvelle facture</Text>
          </CustomBreadcrumbs>
        )
      case '/billing/bill' :
        return (
          <CustomBreadcrumbs>
            <CustomLinkMaterial color="secondary" href="/billing">
              <Text customcolor="white" variant="h6" color="textPrimary">Facturation</Text>
            </CustomLinkMaterial>
            <Text customcolor="white" variant="h6" color="textPrimary">Facture</Text>
          </CustomBreadcrumbs>
        )
      case '/reminders' :
        return 'Rappels'
      case '/providers' :
        return 'Fournisseurs'
      case '/overlap' :
        return 'Recoupement'
      case '/statistics' :
        return 'Statistiques'
      default:
        return '...'
    }
  }
  return (
    <Text variant="h6">
      {checkTitle()}
    </Text>
  )
}

const CustomBreadcrumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-separator {
    color: white !important;
  }
`

const CustomLinkMaterial = styled(LinkMaterial)`
  color: white !important;
`

function LogoPanhard({maxWidth}) {
  return (
    <ImgContainer maxWidth={maxWidth}>
      <img src="/assets/logo-panhard.png"/>
    </ImgContainer>
  )
}

function PersonalInfosInMenu() {
  return (
    <>
      <LogoContainer>
        <LogoPanhard/>
        <div style={{width: '15px'}}/>
        <Text variant="h6">Panhard</Text>
      </LogoContainer>
      <Spacer medium/>
    </>
  )
}

function TopNavigationMenu({nav, setPageSelected}) {
  return (
    <>
      {nav.map((item, index) => (
        <Link to={item.link} key={index} style={{textDecoration: 'none', color: 'rgba(0,0,0,0.87)'}}>
          <ListItem button selected={window.location.pathname === item.link} onClick={() => setPageSelected(item.link)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text}/>
          </ListItem>
        </Link>
      ))}
    </>
  )
}

function BottomNavigationMenu() {
  const {disconnect} = useAuth()

  const nav = [{
    text: 'Déconnexion',
    icon: <ExitToAppIcon/>,
    link: '/',
    action: () => {
      disconnect()
    }
  }]
  return (
    <>
      {nav.map((item, index) => (
          <Link onClick={() => item.action && item.action()} to={item.link} key={index}
                style={{textDecoration: 'none', color: 'rgba(0,0,0,0.87)'}}>
            <ListItem button selected={window.location.pathname === item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text}/>
            </ListItem>
          </Link>
        )
      )}
    </>
  )
}

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
`

const ImgContainer = styled.div`
  display: flex;

  img {
    width: 100%;
    max-width: ${props => props.maxWidth ? props.maxWidth : '30px'};
    height: 100%;
    display: block;
  }
`
const PageContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 1700px;
  justify-content: center;
`

export {
  TopNavigationMenu,
  BottomNavigationMenu,
  TitleHeaderDrawer,
  PersonalInfosInMenu,
  LogoPanhard,
  PageContainer
}
