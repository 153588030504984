const formatOptions = [
	{
		value: 'PERCENTAGE',
		label: 'Pourcentage'
	}, {
		value: 'AMOUNT',
		label: 'Montant'
	}
]

const status = [
	{
		value: 'PAID',
		label: 'Payé'
	}, {
		value: 'OVERDUE',
		label: 'En retard'
	},
	{
		value: 'INCORRECT',
		label: 'Erroné'
	},
	{
		value: 'DRAFT',
		label: 'Brouillon'
	},
	{
		value: 'CREDIT',
		label: 'Avoir'
	},
	{
		value: 'APPROVED',
		label: 'Facturé'
	},
	{
		value: 'ACCOUNTED',
		label: 'Compta'
	}
]

const contractorOptions = [
	{
		value: 'TELAMON',
		label: 'Telamon'
	},
	{
		value: 'RED PARKS MANAGEMENT',
		label: 'Red Parks Management'
	},
	{
		value: 'TELAMON ASSET MANAGEMENT',
		label: 'Telamon Asset Management'
	}
]

const deviseOptions = [
	{
		value: 'EUR',
		label: '€',
	},
	{
		value: 'USD',
		label: '$',
	}
]

const payment = [
	{
		value: 'BANK_TRANSFER',
		label: 'Virement'
	},
	{
		value: 'CHEQUE',
		label: 'Chèque'
	}
]

const temporality = [
	{
		value: 'ONE_MONTH',
		label: '1 mois'
	},
	{
		value: 'THREE_MONTH',
		label: '3 mois'
	},
	{
		value: 'SIX_MONTH',
		label: '6 mois'
	},
	{
		value: 'TWELVE_MONTH',
		label: '12 mois'
	}
]

const temporalityMapper = {
	ONE_MONTH: '1 mois',
	THREE_MONTH: '3 mois',
	SIX_MONTH: '6 mois',
	TWELVE_MONTH: '12 mois'
}

export {
	status,
	deviseOptions,
	payment,
	temporality,
	temporalityMapper,
	contractorOptions,
	formatOptions
}
