import {authenticatedHeaders, axios} from '../config/axios'
import {useQuery} from 'react-query'

async function getSuppliersFilesQuery(page, search) {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/storage/suppliers?page=${page}${search}`, {
    headers: {...authenticatedHeaders}
  })
  return data
}

async function uploadFiles(formData) {
  return await axios.post(`${process.env.REACT_APP_API_URL}/storage/upload`, formData, {headers: {...authenticatedHeaders}})
}

async function deleteFile(id) {
  const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/storage/${id}`, {headers: {...authenticatedHeaders}})
  return data
}

function useSuppliers({page, search}) {
  return {
    uploadFiles,
    getFiles: useQuery(['supplierFile', page + search], () => getSuppliersFilesQuery(page, search), {
      enabled: !!page,
      keepPreviousData: true
    })
  }
}

export {
  useSuppliers,
  getSuppliersFilesQuery,
  deleteFile
}
