import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Text } from '../../common/text'
import { Loader } from '../../common/loader'
import { InputText } from '../../common/inputs/inputText'
import { useForm } from 'react-hook-form'
import { useContract } from '../../../hooks/home.hook'
import { useMutation } from 'react-query'

function DescriptionContainer({data, isLoading}) {
	const {register, handleSubmit, setValue} = useForm()

	const {updateContract} = useContract()

	const {mutate} = useMutation(contract => updateContract(contract))

	const onBlur = (form) => {
		mutate({contractId: data.id, form})
	}

	const handleChange = (e, name) => {
		setValue(name, e)
	}

	useEffect(() => {
		register('description')

	}, [register])


	return (
		<>
			<TitleContainer>
				<Text variant="h5" marginBottom color="primary">Description</Text>
			</TitleContainer>
			{isLoading ? <Loader/> :
				<>
					<ContentContainer>
						<form onBlur={handleSubmit(onBlur)}>
							<InputText
								width="90%"
								littlePadding
								multiline
								defaultValue={data?.description}
								rowsMax={6}
								onChange={(e) => {
									handleChange(e.target.value, 'description')
								}
								}
								label="Description"
							/>
						</form>
					</ContentContainer>
				</>
			}
		</>
	)
}


const TitleContainer = styled.div`
  padding: 20px 30px;
`

const ContentContainer = styled.div`
  width: 100%;
  height: 150px;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    justify-content: center;
  }
`

export
{
	DescriptionContainer
}