import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {LogoPanhard, PageContainer} from '../../layout/drawerMenuOptions'
import {Spacer} from '../../common/spacer'
import {InputText} from '../../common/inputs/inputText'
import {ButtonCustom} from '../../common/button'
import DoneIcon from '@mui/icons-material/Done';
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {Text} from '../../common/text'
import {useMutation} from 'react-query'
import {useUser} from '../../../hooks/user.hook'
import {useAuth} from '../../../hooks/auth.hook'
import {Paper} from "@mui/material";

function LoginContent() {
  const {register, handleSubmit, setValue} = useForm()
  const {setIsLoggedIn} = useAuth()
  const {login} = useUser()
  const [errors, setErrors] = useState(null)
  const {mutate} = useMutation(user => login(user), {
    onMutate: () => setErrors(null),
    onSuccess: (resp) => {
      localStorage.setItem('token', resp.access_token)
      window.location.reload();
    },
    onError: (err) => {
      setErrors(Array.isArray(err.response.data.message) ? err.response.data.message[0] : err.response.data.message)
    }
  })
  const onSubmit = (form) => {
    mutate(form)
  }

  const handleChange = (e, name) => {
    setValue(name, e)
  }

  useEffect(() => {
    register('email')
    register('password')
  }, [register])

  return (
    <Container>
      <PageContainer>
        <LoginContainer>
          <TopPage>
            <LogoPanhard maxWidth={'80px'}/>
          </TopPage>
          <ContentPage>
            <Paper elevation={1}>
              <FormContent onSubmit={handleSubmit(onSubmit)}>
                <InputText
                  width="350px"
                  required
                  type="email"
                  onChange={(e) => handleChange(e.target.value, 'email')}
                  label="Adresse e-mail"
                />
                <InputText
                  width="350px"
                  required
                  type="password"
                  onChange={(e) => handleChange(e.target.value, 'password')}
                  label="Mot de passe"
                />
                <Spacer medium/>
                <ButtonCustom
                  type="submit"
                  width="350px"
                  height="40"
                  startIcon={<DoneIcon/>}
                  background="blue"
                  label="Valider"
                />
                <Spacer medium/>
                <Link to='/password-recovery'><Text variant="body2" color="primary">Mot de passe oublié !</Text></Link>
                <Spacer medium/>
                {errors && <Text variant="subtitle2" color="error">{errors}</Text>}
              </FormContent>
            </Paper>
          </ContentPage>
        </LoginContainer>
      </PageContainer>
    </Container>
  )
}


const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #fafafa;
  justify-content: center;
`

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
`

const TopPage = styled.div`
  width: 100%;
  height: 25vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ContentPage = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FormContent = styled.form`
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export {
  LoginContent
}
