import React from 'react'
import {BillContent} from '../components/contentPages/bill'
import {useInvoices} from '../hooks/invoices.hook'
import {getParamUrl} from '../components/common/utils'

function Bill() {
  const {getInvoicesWithId, updateValidatePayment} = useInvoices({invoiceId: getParamUrl('id')})
  const {data: dataInvoice, isLoading} = getInvoicesWithId

  return (
    <>
      {dataInvoice ? <BillContent dataInvoice={dataInvoice} updateValidatePayment={updateValidatePayment}
                                  isLoading={isLoading}/> : null}
    </>
  )
}

export
{
  Bill
}

