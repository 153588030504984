import React, { useEffect, useState } from 'react'
import { PageContainer } from '../../layout/drawerMenuOptions'
import { Text } from "../../common/text";
import styled from "styled-components";
import { Paper } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { UseExportStats, UseStats } from "../../../hooks/stats.hook";
import { Loader } from "../../common/loader";
import { format } from "date-fns";
import { SelectText } from "../../common/inputs/inputSelectWithText";
import { ButtonCustom } from "../../common/button";
import ListIcon from "@mui/icons-material/List";
import { Workbook } from "exceljs";
import * as fs from 'file-saver'

function StatisticsContent() {
  const currentYear = format(new Date(), 'yyyy')
  const [year, setYear] = useState({label: currentYear, value: currentYear})
  const queryInfo = UseStats(year?.value)

  const queryExportInfo = UseExportStats(year?.value)
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const contractName = ['SWEN', 'MOSAIC', 'STAR', 'IVANHOE CAMBRIDGE', 'OPCI FONCIÈRE 86']
  const labels = ['T1', 'T2', 'T3', 'T4'];

  const formatterCurrency = Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  })


  const retrieveOptions = (name) => {
    const data = queryInfo.data?.datas?.find(e => e.contract_name === name)
    const options = {
      plugins: {
        tooltip: false,
        datalabels: {
          formatter: function (value) {
            if (value === 0) {
              return null
            }
            return formatterCurrency.format(value)
          },
        },
        title: {
          display: true,
          text: data.contract_name,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };
    return options
  }

  const retrieveDatas = (name) => {
    const data = queryInfo.data?.datas?.find(e => e.contract_name === name)
    const datasets = {
      labels,
      datasets: [
        {
          label: 'Ponctuel',
          data: data?.one_shot?.map(e => e.amount),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Récurrent',
          data: data?.recurring?.map(e => e.amount),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }
      ]
    }

    const TotalOneShot = datasets.datasets.find(e => e.label === 'Ponctuel').data.reduce((a, b) => a + b, 0)
    const TotalRecurring = datasets.datasets.find(e => e.label === 'Récurrent').data.reduce((a, b) => a + b, 0)
    return {datasets, TotalOneShot, TotalRecurring}

  }


  const inputOptions = queryInfo?.data?.years?.map(e => ({
      label: e.value.toString(),
      value: e.value.toString()
    })
  )

  useEffect(() => {
    queryInfo.refetch().then(r => r)
    queryExportInfo.refetch().then(r => r)
  }, [year])

  return (
    <PageContainer>
      <StatsContainer>
        <InfosContainer>
          <Paper elevation={1} style={{width: "100%"}}>
            <TitleContainer>
              <Text variant="h5" marginBottom
                    color="primary">Chiffre d'Affaires facturé par Mandat</Text>
              {queryInfo && queryExportInfo &&
                <ButtonContainer>
                  <ButtonCustom
                    label={!year ? 'Selectionner une année' : `Exporter les données de ${year?.value}`}
                    size="large"
                    disabled={!year}
                    startIcon={<ListIcon/>}
                    background="green"
                    onClick={() => downloadExcel(queryExportInfo, year.value)}
                    height={56}/>
                  <SelectText
                    label="Année"
                    disableClearable
                    options={inputOptions}
                    optionKey={'value'}
                    value={year}
                    onChange={(e, value) => setYear(value)}
                  />
                </ButtonContainer>
              }
            </TitleContainer>
            <ContentContainer>
              {
                queryInfo.isLoading ? (
                  <Loader/>
                ) : queryInfo.isError ? (
                  <p>{queryInfo.error.message}</p>
                ) : (
                  <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                    {
                      contractName.map((e, i) => (
                        <div key={e + i.toString()} style={{maxHeight: '500px', width: '45%', padding: '20px 0'}}>
                          <Bar style={{height: '100%', width: '100%'}}
                               options={queryInfo && retrieveOptions(e)}
                               data={queryInfo && retrieveDatas(e).datasets}
                          />
                          <div style={{display: "flex", justifyContent: "space-around"}}>
                            <Text variant="caption">Total ponctuel
                              : {formatterCurrency.format(retrieveDatas(e).TotalOneShot)}</Text>
                            <Text variant="caption">Total récurrent
                              : {formatterCurrency.format(retrieveDatas(e).TotalRecurring)}</Text>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </ContentContainer>
          </Paper>
        </InfosContainer>
      </StatsContainer>
    </PageContainer>
  )
}

const downloadExcel = (data, year) => {
  const workbook = new Workbook()
  const fName = `Extraction du ${format(new Date(), 'dd-MM-yyyy')} - CA Mandats`
  Object.keys(data.data).forEach((key) => {
    const worksheet = workbook.addWorksheet(key)
    const header = ['IPR', 'T1', 'T2', 'T3', 'T4', 'TOTAL']
    worksheet.getRow(3).values = header
    worksheet.getRow(1).font = {
      size: 16,
      bold: true,
      color: {argb: 'FFFFFFFF'}
    }
    worksheet.getRow(3).font = {
      size: 14,
      bold: true
    }
    worksheet.getRow(3).alignment = {vertical: 'top', horizontal: 'left'}
    worksheet.getCell('A1').value = `${worksheet.name} ${year}`
    const IPR = Object.keys(data.data[key])
    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: '2C3C93'}
    }

    worksheet.columns = [
      {width: 50},
      {width: 20},
      {width: 20},
      {width: 20},
      {width: 20},
      {width: 20},
    ]
    let number = 5

    IPR.forEach((ipr) => {
      number += 1
      const tmp = data.data[key][ipr].map(e => e.amount)
      const total = data.data[key][ipr].reduce((prev, next) => prev + next.amount, 0)
      tmp.unshift(ipr)
      tmp.push(total)
      worksheet.addRow(tmp)
    })

    worksheet.getCell(`A${number}`).value = 'Total';

    worksheet.getCell(`B${number}`).value = {formula: `SUM(B4:B${number - 1})`};
    worksheet.getCell(`C${number}`).value = {formula: `SUM(C4:C${number - 1})`};
    worksheet.getCell(`D${number}`).value = {formula: `SUM(D4:D${number - 1})`};
    worksheet.getCell(`E${number}`).value = {formula: `SUM(E4:E${number - 1})`};
    worksheet.getCell(`F${number}`).value = {formula: `SUM(F4:F${number - 1})`};

    worksheet.getRow(number).font = {
      size: 14,
      bold: true,
      color: {argb: 'FF0000'}
    }

    worksheet.getColumn(2).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"'
    worksheet.getColumn(3).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"'
    worksheet.getColumn(4).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"'
    worksheet.getColumn(5).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"'
    worksheet.getColumn(6).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"'
    worksheet.mergeCells('A1', 'F1')
  })

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    fs.saveAs(blob, fName + '-' + new Date().valueOf() + '.xlsx')
  })
}

const StatsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const InfosContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 30px 50px 30px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export {
  StatisticsContent
}
