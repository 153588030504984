import {useQuery} from 'react-query'
import {authenticatedHeaders, axios} from '../config/axios'


async function getInvoices(page, search) {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/invoices?page=${page}${search}`, {headers: {...authenticatedHeaders}})
  return data
}

async function getInvoicesWithId(invoiceId) {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}`, {headers: {...authenticatedHeaders}})
  return data
}

async function createInvoices({form}) {
  const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/invoices`, {...form}, {headers: {...authenticatedHeaders}})
  return data
}

async function updateInvoices({invoicesId, form}) {
  const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoicesId}`, {...form.form}, {headers: {...authenticatedHeaders}})
  return data
}

async function deleteInvoices({invoicesId}) {
  const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/invoices/${invoicesId}`, {headers: {...authenticatedHeaders}})
  return data
}

async function validateInvoices({invoicesId}) {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/invoices/${invoicesId}/validate`, {headers: {...authenticatedHeaders}})
  return data
}

async function updateValidatePayment({invoicesId, form}) {
  const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoicesId}/validate_payment`, {...form}, {headers: {...authenticatedHeaders}})
  return data
}

async function getCreditWithId({invoiceId}) {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/credit`, {headers: {...authenticatedHeaders}})
  return data
}

function usePaginateInvoices({page, search}) {
  return {
    getInvoices: useQuery(['getInvoices', page + search], () => getInvoices(page, search), {
      keepPreviousData: true,
      enabled: !!page
    })
  }
}

function useInvoices({invoiceId = null} = {}) {
  return {
    getInvoicesWithId: useQuery(['getInvoicesWithId', invoiceId], () => getInvoicesWithId(invoiceId), {enabled: !!invoiceId}),
    getCreditWithId,
    createInvoices,
    updateInvoices,
    deleteInvoices,
    validateInvoices,
    updateValidatePayment
  }
}

export {
  useInvoices,
  usePaginateInvoices
}
