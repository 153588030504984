import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {ButtonCustom} from '../../common/button'
import CloseIcon from '@mui/icons-material/Close';
import {SelectText} from '../../common/inputs/inputSelectWithText'
import {Calendar} from '../../common/inputs/calendar'
import {temporality} from '../../../data/data'
import {InputText} from '../../common/inputs/inputText'
import DoneIcon from '@mui/icons-material/Done';
import styled from 'styled-components'
import {theme} from '../../../config/theme'
import {useMutation, useQueryClient} from 'react-query'
import {useContract} from '../../../hooks/home.hook'
import {IconButton} from "@mui/material";

function RemindersForm({data, setOpen}) {

  const queryClient = useQueryClient()
  const {createReminders} = useContract()

  const {mutate} = useMutation(contract => createReminders(contract), {
    onSettled: () => {
      queryClient.invalidateQueries('getReminders')
    }
  })

  const [contractName, setContractName] = useState(null)
  const {register, handleSubmit, setValue} = useForm()

  const onSubmit = (form) => {
    mutate({contractId: data.id, form})
    setOpen(false)
  }

  const handleChange = (e, name) => {
    setValue(name, e)
  }

  useEffect(() => {
    register('title')
    register('contract')
    register('billing_entity')
    register('start_date')
    register('timeframe')
    register('email_address')
  }, [register])


  return (
    <ContentContainer>
      <AddRowContainer>
        <ButtonCloseContainer>
          <IconButton size="small" onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </ButtonCloseContainer>
        <FormContent onSubmit={handleSubmit(onSubmit)}>
          <InputText
            required
            width="300px"
            onChange={(e) => handleChange(e.target.value, 'title')}
            label="Nom du rappel"
          />
          <SelectText
            label="Mandat"
            options={data}
            value={contractName ? contractName : null}
            optionKey={'contract_name'}
            required
            onChange={(e, value) => {
              setContractName(value)
              handleChange({connect: {id: value.id}}, 'contract')
            }}
            width="300px"
          />
          <Calendar
            label="Date de début"
            defaultValue={null}
            onChange={(e) => handleChange(e, 'start_date')}
          />
          <SelectText
            label="Temporalité"
            options={temporality}
            required
            optionKey={'label'}
            onChange={(e, value) => {
              handleChange(value.value, 'timeframe')
            }}
          />
          <InputText
            type="email"
            required
            width="300px"
            onChange={(e) => handleChange(e.target.value, 'email_address')}
            label="E-mail"
          />
          <ContentContainer>
            {contractName && <SelectText
              label="SPV"
              width="800px"
              multiple
              onChange={(e, value) => handleChange({connect: value.map(e => ({id: e.id}))}, 'billing_entity')}
              options={contractName?.billing_entity}
              optionKey={'company_name'}
            />}
          </ContentContainer>
          <ButtonSubmitContainer>
            <ButtonCustom
              type="submit"
              startIcon={<DoneIcon/>}
              background="blue"
              label="Valider"
            />
          </ButtonSubmitContainer>
        </FormContent>
      </AddRowContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 30px 50px 30px;
  justify-content: center;
  align-items: center;
`

const AddRowContainer = styled.div`
  width: 90%;
  height: auto;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid ${theme.grey};
  position: relative;
`

const FormContent = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const ButtonCloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonSubmitContainer = styled.div`
  padding: 30px 0;
  position: absolute;
  bottom: -20px;
  right: 5px;
`

export {
  RemindersForm
}
