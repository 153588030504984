import React from 'react'
import {Route} from 'react-router-dom'
import {Login} from '../../pages/login'
import {PasswordRecovery} from '../../pages/password-recovery'

function UnAuthenticatedApp() {
  return (
    <>
      <Route exact path="/">
        <Login/>
      </Route>
      <Route exact path="/password-recovery">
        <PasswordRecovery/>
      </Route>
    </>
  )
}

export {
  UnAuthenticatedApp
}
