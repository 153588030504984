import React from 'react'
import {Text} from '../../common/text'
import styled from 'styled-components'
import {theme} from '../../../config/theme'
import {Spacer} from '../../common/spacer'
import {Link} from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {createKey, useDevise} from '../../common/utils'
import {format} from 'date-fns'
import {Chip, Divider, IconButton} from "@mui/material";

function MandateCard({data}) {
  return (
    <>
      {data && data.map((mandate, index) => {
        return (
          <CardMandateContainer key={createKey(index)}>
            <CustomLink to={{
              pathname: '/mandate',
              search: `?id=${mandate.id}`
            }}>
              <CardHeader>
                <IconButtonCustom size="small">
                  <ArrowForwardIosIcon/>
                </IconButtonCustom>
                <Text variant="h5" customcolor={theme.white}>
                  {mandate.contract_name}
                </Text>
              </CardHeader>
            </CustomLink>
            <CardContent>
              <TopContent>
                <Spacer medium/>
                <TopContentText>
                  <div>
                    <Text color="primary" variant="subtitle2">Investisseur :</Text>
                    <div style={{width: '10px'}}/>
                    <Text variant="subtitle2">{mandate.investor}</Text>
                  </div>
                  <div>
                    <Text color="primary" variant="subtitle2">Date de début :</Text>
                    <div style={{width: '10px'}}/>
                    {mandate.start_date &&
                    <Text
                      variant="subtitle2">{mandate?.start_date ? format(new Date(mandate?.start_date), 'dd/MM/yyyy') : null}</Text>}
                  </div>
                </TopContentText>
                <TopContentText>
                  <div>
                    <Text color="primary" variant="subtitle2">Mandataire :</Text>
                    <div style={{width: '10px'}}/>
                    <Text variant="subtitle2">{mandate.contractor.company_name}</Text>
                  </div>
                  <div>
                    <Text color="primary" variant="subtitle2">Durée du mandat :</Text>
                    <div style={{width: '10px'}}/>
                    {mandate.contract_duration && <Text variant="subtitle2">{mandate.contract_duration} ans</Text>}
                  </div>
                </TopContentText>
                <Divider/>
                <Spacer small/>
                <TopContentText2>
                  <div>
                    <Text color="primary" variant="subtitle2">Honoraires A.M. :</Text>
                    <div style={{width: '10px'}}/>
                    {mandate.asset_management_fees && <Text variant="subtitle2">{mandate.asset_management_fees}%</Text>}
                  </div>
                </TopContentText2>
                <TopContentText2>
                  <div>
                    <Text color="primary" variant="subtitle2">Honoraires P.M. :</Text>
                    <div style={{width: '10px'}}/>
                    {mandate.property_management_fees &&
                    <Text variant="subtitle2">{mandate.property_management_fees}%</Text>}
                  </div>
                </TopContentText2>
                <TopContentText2>
                  <div>
                    <Text color="primary" variant="subtitle2">Prix d'acquisition du portefeuille :</Text>
                    <div style={{width: '10px'}}/>
                    {mandate.purchase_price_portfolio &&
                    <Text variant="subtitle2">{useDevise('€', mandate.purchase_price_portfolio)}</Text>}
                  </div>
                </TopContentText2>
              </TopContent>
              <Content>
                <Divider/>
                <Spacer medium/>
                <Text color="primary" variant="subtitle2"
                      marginBottom>{mandate.places.length} {mandate.places.length > 1 ? 'Sites :' : 'Site :'}</Text>
                <ListContainer>
                  {mandate.places.map((place, i) => {
                    return (
                      <div key={createKey(i)} style={{
                        margin: '3px 2px'
                      }}>
                        <Chip label={place.name} size="small"/>
                      </div>
                    )
                  })
                  }
                </ListContainer>
                <Spacer large/>
              </Content>
            </CardContent>
          </CardMandateContainer>
        )
      })}
    </>
  )
}

const CardMandateContainer = styled.div`
  width: 48%;
  height: 420px;
  min-width: 639px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  background: white;
  border: 2px solid ${theme.default};
  margin: 20px 10px;
  border-radius: 4px;
  padding-bottom: 10px;
`

const CardHeader = styled.div`
  width: 100%;
  height: 15%;
  position: relative;
  background-color: ${theme.dark};
  display: flex;
  opacity: 0.9;
  border-radius: 4px 4px 0 0;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.2s;

  :hover {
    opacity: 1;
  }
`

const CardContent = styled.div`
  display: flex;
  height: 85%;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  align-items: center;
`

const TopContent = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const TopContentText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  div {
    width: 50%;
    display: flex;
  }
`

const TopContentText2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  div {
    display: flex;
  }
`

const Content = styled.div`
  width: 90%;
`

const IconButtonCustom = styled(IconButton)`
  color: ${theme.white} !important;
  position: absolute !important;
  right: 6px !important;
  top: 50% !important;
  transform: translateY(-50%);
`

const CustomLink = styled(Link)`
  text-decoration: none !important;
`

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export {
  MandateCard
}
