import { useQuery } from 'react-query'
import { authenticatedHeaders, axios } from '../config/axios'

async function getContract() {
	const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/contract`, {headers: {...authenticatedHeaders}})
	return data
}

async function getContractWithId(params) {
	const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/contract/${params}`, {headers: {...authenticatedHeaders}})
	return data
}

async function updateContract({contractId, form}) {
	const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/contract/${contractId}`, {...form}, {headers: {...authenticatedHeaders}})
	return data
}

async function deleteContract({contractId}) {
	const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/places/${contractId}`, {headers: {...authenticatedHeaders}})
	return data
}

async function createBillingEntity({contractId, form}) {
	const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/billingentity`, {...form, contractId}, {headers: {...authenticatedHeaders}})
	return data
}

async function updateBillingEntity({contractId, form}) {
	const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/billingentity/${contractId}`, {...form}, {headers: {...authenticatedHeaders}})
	return data
}

async function deleteBillingEntity({contractId}) {
	const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/billingentity/${contractId}`, {headers: {...authenticatedHeaders}})
	return data
}

async function getReminders() {
	const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/notifier`, {headers: {...authenticatedHeaders}})
	return data
}

async function createReminders({contractId, form}) {
	const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/notifier`, {...form, contractId}, {headers: {...authenticatedHeaders}})
	return data
}

async function updateReminders({contractId, form}) {
	const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/notifier/${contractId}`, {...form}, {headers: {...authenticatedHeaders}})
	return data
}

async function deleteReminders({contractId}) {
	const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/notifier/${contractId}`, {headers: {...authenticatedHeaders}})
	return data
}

async function getFilters() {
	const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/filters`, {headers: {...authenticatedHeaders}})
	return data
}

function useContract(params) {
	return {
		getContract: useQuery('getContract', getContract),
		getContractWithId: useQuery(['getContractWithId', params], () => getContractWithId(params), {enabled: !!params}),
		updateContract,
		deleteContract,
		createBillingEntity,
		updateBillingEntity,
		deleteBillingEntity,
		getReminders: useQuery('getReminders', getReminders),
		createReminders,
		updateReminders,
		deleteReminders,
		getFilters: useQuery('getFilters', getFilters),
	}
}

export {
	useContract
}
