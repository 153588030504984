import React, {useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {useContract} from '../../../hooks/home.hook'
import {useForm} from 'react-hook-form'
import {ButtonCustom} from '../../common/button'
import CloseIcon from '@mui/icons-material/Close';
import {InputText} from '../../common/inputs/inputText'
import {SelectText} from '../../common/inputs/inputSelectWithText'
import DoneIcon from '@mui/icons-material/Done';
import styled from 'styled-components'
import {theme} from '../../../config/theme'
import {IconButton} from "@mui/material";

function UpdateFormBillingEntities({dataAll, setOpen, data}) {

  const queryClient = useQueryClient()
  const {updateBillingEntity} = useContract()
  const {mutate} = useMutation(contract => updateBillingEntity(contract), {
    onSettled: () => {
      queryClient.invalidateQueries('getContractWithId')
    }
  })

  const {register, handleSubmit, setValue} = useForm()

  const onSubmit = (form) => {
    mutate({contractId: data.id, form})
    setOpen(false)
  }

  const handleChange = (e, name) => {
    setValue(name, e)
  }

  useEffect(() => {
    register('company_name')
    register('company_type')
    register('address')
    register('email_contact')
    register('siret')
    register('places')
  }, [register])

  return (
    <ContentContainer>
      <AddRowContainer>
        <ButtonCloseContainer>
          <IconButton size="small" onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </ButtonCloseContainer>
        <FormContent onSubmit={handleSubmit(onSubmit)}>
          <InputText
            width="350px"
            required
            defaultValue={data?.company_name}
            onChange={(e) => handleChange(e.target.value, 'company_name')}
            label="Raison sociale"
          />
          <InputText
            width="350px"
            required
            defaultValue={data?.company_type}
            onChange={(e) => handleChange(e.target.value, 'company_type')}
            label="Type de société"
          />
          <InputText
            width="350px"
            required
            defaultValue={data?.address}
            onChange={(e) => handleChange(e.target.value, 'address')}
            label="Adresse"
          />
          <InputText
            width="350px"
            type="email"
            required
            defaultValue={data?.email_contact}
            onChange={(e) => handleChange(e.target.value, 'email_contact')}
            label="E-mail"
          />
          <InputText
            width="350px"
            required
            defaultValue={data?.siret}
            onChange={(e) => handleChange(e.target.value, 'siret')}
            label="Siret"
          />
          <PlaceContainer>
            <SelectText
              label="Site(s)"
              options={dataAll?.places}
              optionKey={'name'}
              multiple
              defaultValue={data?.places}
              onChange={(e, value) => handleChange({set: value.map(e => ({id: e.id}))}, 'places')}
              width="100%"
            />
          </PlaceContainer>
          <ButtonSubmitContainer>
            <ButtonCustom
              type="submit"
              startIcon={<DoneIcon/>}
              background="blue"
              label="Modifier entité de facturation"
            />
          </ButtonSubmitContainer>
        </FormContent>
      </AddRowContainer>
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 30px 50px 30px;
  justify-content: center;
  align-items: center;
`


const AddRowContainer = styled.div`
  height: auto;
  border-radius: 4px;
  padding: 20px 20px;
  position: relative;
  width: 90%;
  border: 1px solid ${theme.grey};
`

const ButtonCloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const FormContent = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const PlaceContainer = styled.div`
  padding: 60px 0;
  width: 70%;
`

const ButtonSubmitContainer = styled.div`
  padding: 30px 0;
  position: absolute;
  bottom: -20px;
  right: 5px;
`


export {
  UpdateFormBillingEntities
}
