import React from 'react'
import {ItemsContainer} from './cssInputsCommon'
import {Autocomplete, TextField} from "@mui/material";

function SelectText({
                      label,
                      width,
                      disabled,
                      onBlur,
                      littlePadding = false,
                      onChange,
                      multiple,
                      value,
                      defaultValue,
                      options = [],
                      size,
                      id,
                      disableClearable,
                      name,
                      optionKey,
                      inputRef,
                    }) {
  return (
    <ItemsContainer littlePadding={littlePadding} width={width}>
      <Autocomplete
        id="select_text"
        fullWidth
        label={label}
        disableClearable={disableClearable}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        size={size}
        defaultValue={defaultValue}
        autoComplete
        multiple={multiple}
        getOptionLabel={option => option[optionKey]}
        options={options}
        renderInput={(params) => <TextField {...params}
                                            label={label}
                                            variant="outlined"
                                            name={name}
                                            id={id}
                                            inputRef={inputRef}
        />}
      />
    </ItemsContainer>
  )
}

export {
  SelectText
}




