import React, {useState} from 'react'
import styled from 'styled-components'
import {useMutation, useQueryClient} from 'react-query'
import DoneIcon from '@mui/icons-material/Done';
import {Controller, useForm} from 'react-hook-form'
import {useHistory} from 'react-router-dom'
import {useInvoices} from '../../../hooks/invoices.hook'
import {useContract} from '../../../hooks/home.hook'
import {setCurrency} from '../../common/utils'
import {NewBillContent} from '../newBill'
import {PageContainer} from '../../layout/drawerMenuOptions'
import {ButtonCustom} from '../../common/button'
import {Text} from '../../common/text'
import {DeleteInvoices} from '../newBill/buttonSaveInvoice'
import {InputText} from '../../common/inputs/inputText'
import {Calendar} from '../../common/inputs/calendar'
import {Loader} from '../../common/loader'
import {CircularProgress, Paper} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

function BillContent({dataInvoice, updateValidatePayment, isLoading}) {
  const queryCache = useQueryClient()
  const {deleteInvoices} = useInvoices()
  const {getContract, getFilters} = useContract()
  const data = getContract.data
  const typeOptions = getFilters.data
  const [openPopUp, setOpenPopUp] = useState(false)

  const {mutate} = useMutation("invoice", invoicesId => updateValidatePayment(invoicesId), {
    onSettled: () => {
      queryCache.invalidateQueries('getInvoicesWithId').then(r => r)
    }
  })

  const {handleSubmit, control} = useForm({
    defaultValues: {
      amount_total_paid: dataInvoice && dataInvoice.total_with_taxes ? dataInvoice.total_with_taxes : 0,
      payment_date: null
    }
  })

  const onSubmit = (form) => {
    const formValid = {
      amount_total_paid: form.amount_total_paid.toString(),
      payment_date: form.payment_date
    }
    mutate({invoicesId: dataInvoice.id, form: formValid})
    setOpenPopUp(false)
  }

  const toggleValidator = () => {
    setOpenPopUp(!openPopUp)
  }

  if (dataInvoice?.status === 'DRAFT') {
    return (
      <>
        {dataInvoice && data ? <NewBillContent data={data} typeOptions={typeOptions} dataInvoice={dataInvoice}/> : null}
      </>
    )
  } else {

    return (
      <PageContainer>
        <Container>
          <InfosContainer>
            <Paper elevation={1}>
              <TitleContainer>
                <Text variant="h5" marginBottom color="primary">Facture</Text>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {dataInvoice?.status === 'CREDIT' &&
                  <DeleteInvoices deleteInvoices={deleteInvoices} id={dataInvoice?.id}/>}
                  {dataInvoice?.status !== 'CREDIT' &&
                  <>
                    <GetCredit dataInvoice={dataInvoice}/>
                    <ButtonCustom
                      startIcon={openPopUp ? <CancelIcon/> : <AddIcon/>}
                      background={openPopUp ? 'red' : 'blue'}
                      onClick={() => toggleValidator()}
                      label={openPopUp ? 'Annuler' : 'Ajouter un règlement'}
                    />
                  </>
                  }
                </div>
              </TitleContainer>
              {openPopUp && <ContentContainer>
                <InputsContainer>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name='amount_total_paid'
                      control={control}
                      render={({value, onChange, ref}) => (
                        <InputText
                          label="Montant réglé"
                          onChange={onChange}
                          type='number'
                          required
                          icon={setCurrency(dataInvoice.currency).label}
                          value={value}
                          inputRef={ref}
                        />
                      )}
                    />
                    <Controller
                      name='payment_date'
                      control={control}
                      render={({value, onChange, ref}) => (
                        <Calendar
                          label="Date de règlement"
                          defaultValue={value}
                          required
                          onChange={onChange}
                          inputRef={ref}
                        />
                      )}
                    />
                    <ButtonCustom
                      type='sumbmit'
                      width="150px"
                      height='56'
                      startIcon={<DoneIcon/>}
                      background="blue"
                      label="Valider"
                    />
                  </Form>
                </InputsContainer>
              </ContentContainer>
              }
              <ContentContainer>
                <PdfContainer>
                  {!isLoading && dataInvoice && dataInvoice.url ?
                    <embed src={dataInvoice?.url} width="80%" height="1120" type='application/pdf'/> :
                    <Loader height='600px'/>
                  }
                  {dataInvoice && !dataInvoice.url &&
                  <TextContainer>
                    <Text variant="h5" marginBottom color="error">Votre facture n'est pas accessible...</Text>
                  </TextContainer>
                  }
                </PdfContainer>
              </ContentContainer>
            </Paper>
          </InfosContainer>
        </Container>
      </PageContainer>
    )
  }
}

function GetCredit({dataInvoice}) {
  const queryCache = useQueryClient()
  let history = useHistory()
  const {getCreditWithId} = useInvoices()
  const {mutate, isLoading} = useMutation('getCreditWithId', invoiceId => getCreditWithId(invoiceId), {
    onSettled: () => {
      queryCache.invalidateQueries('getInvoicesWithId').then(r => r)
    },
    onSuccess: () => {
      history.push('/billing')
    }
  })

  const onSubmit = () => {
    mutate({invoiceId: dataInvoice.id})
  }

  return (
    <>
      {isLoading ?
        <div style={{marginRight: '40px'}}>
          <CircularProgress color="secondary" size={20}/>
        </div>
        :
        <ButtonCustom
          startIcon={<AddIcon/>}
          background="orange"
          onClick={onSubmit}
          label="Générer un avoir"
        />
      }
    </>
  )
}

export {
  BillContent
}

const PdfContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 50px 0;
`

const TextContainer = styled.div`
  position: absolute;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const InfosContainer = styled.div`
  width: 100%;
  margin: 20px 0;
`

const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px 30px 5px 30px;
  justify-content: flex-start;
`

const InputsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const Form = styled.form`
  width: auto;
  display: flex;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 10px;
`


