import {useForm} from 'react-hook-form'
import React, {useEffect} from 'react'
import {InputText} from '../../common/inputs/inputText'
import {Text} from '../../common/text'
import styled from 'styled-components'
import {Calendar} from '../../common/inputs/calendar'
import {Spacer} from '../../common/spacer'
import {Loader} from '../../common/loader'
import {useContract} from '../../../hooks/home.hook'
import {useMutation} from 'react-query'
import {FormControl} from "@mui/material";

function GeneralInformation({data, isLoading}) {
  const {register, handleSubmit, setValue} = useForm()

  const {updateContract} = useContract()

  const {mutate} = useMutation(contract => updateContract(contract))

  const onBlur = (values) => {
    const form = Object.fromEntries(Object.entries(values).filter(([_, v]) => _ !== "" && v))
    mutate({contractId: data.id, form})
  }

  const handleChange = (e, name) => {
    setValue(name, e)
  }

  useEffect(() => {
    register('investor')
    register('typology')
    register('contract_duration')
    register('start_date')
    register('end_date')
    register('asset_management_fees')
    register('property_management_fees')
    register('fees_for_major_work')
    register('acquisition_date')
    register('acquisition_fees')
    register('purchase_price_portfolio')
  }, [register])

  return (
    <>
      {isLoading ?
        <>
          <TitleContainer>
            <Text variant="h5" marginBottom color="primary">Informations générales</Text>
          </TitleContainer>
          <Loader/>
        </>
        :
        <FormControl fullWidth onBlur={handleSubmit(onBlur)}>
          <TitleContainer>
            <Text variant="h5" marginBottom color="primary">Informations générales</Text>
          </TitleContainer>
          <ContentContainer>
            <InputText label="Investisseur"
                       name="investor"
                       width="350px"
                       littlePadding
                       inputRef={register}
                       defaultValue={data?.investor}
                       onChange={(e) => handleChange(e.target.value, 'investor')}
            />
            <InputText label="Mandataire"
                       width="350px"
                       littlePadding
                       inputRef={register}
                       value={data?.contractor.company_name}
                       onChange={() => null}
            />
            <InputText label="Typologie"
                       width="350px"
                       littlePadding
                       inputRef={register}
                       defaultValue={data?.typology}
                       onChange={(e) => handleChange(e.target.value, 'typology')}
            />
          </ContentContainer>
          <ContentContainer>
            <InputText
              label="Durée du mandat"
              type="number"
              width="350px"
              icon="Année"
              littlePadding
              inputRef={register}
              defaultValue={data?.contract_duration}
              min={0}
              onChange={(e) => handleChange(e.target.value, 'contract_duration')}
            />
            <Calendar
              label="Date début mandat"
              width="350px"
              littlePadding
              defaultValue={data?.start_date}
              onChange={(e) => handleChange(e, 'start_date')}
            />

            <Calendar
              label="Date fin mandat"
              width="350px"
              littlePadding
              defaultValue={data?.end_date}
              onChange={(e) => handleChange(e, 'end_date')}
            />

          </ContentContainer>

          <TitleContainer>
            <Text variant="h5" marginBottom color="primary">Rémunération</Text>
          </TitleContainer>
          <ContentContainer>
            <InputText label="Honoraires A.M."
                       defaultValue={data?.asset_management_fees}
                       type='number'
                       icon="%"
                       min={0}
                       littlePadding
                       max={100}
                       onChange={(e) => handleChange(e.target.value, 'asset_management_fees')}
            />

            <InputText label="Honoraires P.M."
                       defaultValue={data?.property_management_fees}
                       type='number'
                       littlePadding
                       icon="%"
                       min={0}
                       max={100}
                       onChange={(e) => handleChange(e.target.value, 'property_management_fees')}
            />
            <InputText label="Honoraire gros travaux"
                       defaultValue={data?.fees_for_major_work}
                       type='number'
                       icon="€"
                       min={0}
                       littlePadding
                       onBlur={(e) => e.target.value = Number(e.target.value).toFixed(2)}
                       onChange={(e) => handleChange(e.target.value, 'fees_for_major_work')}
            />

          </ContentContainer>
          <TitleContainer>
            <Text variant="h5" marginBottom color="primary">Acquisition</Text>
          </TitleContainer>
          <ContentContainer>
            <Calendar
              label="Date d'acquisition"
              littlePadding
              defaultValue={data?.acquisition_date}
              onChange={(e) => handleChange(e, 'acquisition_date')}
            />
            <InputText label="Acquisition fees"
                       defaultValue={data?.acquisition_fees}
                       type='number'
                       littlePadding
                       icon="€"
                       min={0}
                       onBlur={(e) => e.target.value = Number(e.target.value).toFixed(2)}
                       onChange={(e) => handleChange(e.target.value, 'acquisition_fees')}
            />
            <InputText label="Prix d'acquisition du portefeuille"
                       defaultValue={data?.purchase_price_portfolio}
                       type='number'
                       littlePadding
                       icon="€"
                       width="280px"
                       min={0}
                       onBlur={(e) => e.target.value = Number(e.target.value).toFixed(2)}
                       onChange={(e) => handleChange(e.target.value, 'purchase_price_portfolio')}
            />
          </ContentContainer>
          <Spacer large/>
        </FormControl>
      }
    </>
  )
}

const TitleContainer = styled.div`
  padding: 20px 30px;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px;
  justify-content: flex-start;
`

export {
  GeneralInformation
}
