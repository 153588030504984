import { axios } from '../config/axios'

async function login({email, password}) {
	const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, {email, password})
	return data
}

function useUser() {
	return {
		login,
	}
}

export {
	useUser
}
