import React, {useEffect, useState} from 'react'
import {PageContainer} from '../../layout/drawerMenuOptions'
import {useForm, Controller, useFieldArray} from 'react-hook-form'
import {InputText} from '../../common/inputs/inputText'
import styled from 'styled-components'
import {SelectText} from '../../common/inputs/inputSelectWithText'
import {Calendar} from '../../common/inputs/calendar'
import {Text} from '../../common/text'
import {deviseOptions, payment, formatOptions} from '../../../data/data'
import {Spacer} from '../../common/spacer'
import {theme} from '../../../config/theme'
import {ButtonCustom} from '../../common/button'
import AddIcon from '@material-ui/icons/Add'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import {createKey, cropAndAddDots, setCurrency, setFormat, setPayment, useDevise} from '../../common/utils'
import CancelIcon from '@material-ui/icons/Cancel'
import {useHistory} from 'react-router-dom'
import {Loader} from '../../common/loader'
import {ButtonSaveInvoice} from './buttonSaveInvoice'
import VisibilityIcon from '@material-ui/icons/Visibility'
import {Chip, IconButton, Paper} from "@mui/material";

const writtenNumber = require('written-number');

function NewBillContent({data, typeOptions, dataInvoice}) {
  const [isLoading, getIsLoading] = useState(false)
  const [textTotalWithTextManuallyHandled, setTextTotalWithTextManuallyHandled] = useState(false)
  let history = useHistory()
  const [openPdf, setOpenPdf] = useState(false)
  const togglePdf = () => {
    setOpenPdf(!openPdf)
  }

  const {handleSubmit, setValue, getValues, control, watch} = useForm({
    defaultValues: {
      contract_name: dataInvoice ? dataInvoice.contract : null,
      billing_entity: dataInvoice ? dataInvoice.billing_entity : null,
      general_description: dataInvoice ? dataInvoice.description : '',
      analytic_code: dataInvoice ? dataInvoice.analytic_code : null,
      devise: dataInvoice ? setCurrency(dataInvoice.currency) : deviseOptions[0],
      payment: dataInvoice ? setPayment(dataInvoice.payment_method) : payment[0],
      amount_initial_name: dataInvoice ? dataInvoice.amount_initial_name : 'Montant',
      fees_name: dataInvoice ? dataInvoice.fees_name : 'Honoraires',
      billing_date: dataInvoice ? dataInvoice.invoice_date : new Date(),
      due_date: dataInvoice ? dataInvoice.due_date : new Date(),
      total_without_taxes: Number(0),
      total_with_taxes: Number(0),
      text_total_with_taxes: dataInvoice ? dataInvoice.text_total_with_taxes : null
    }
  })

  const [devise, setDevise] = useState('€')
  const contract_name = watch('contract_name')
  const billing_entity = watch('billing_entity')
  const analytic_code = watch('analytic_code')
  const listFees = watch('listFees')


  useEffect(() => {
    if (dataInvoice) {
      setValue('contract_name', dataInvoice.contract)
      setValue('billing_entity', dataInvoice.billing_entity)
      setValue('general_description', dataInvoice.description)
      setValue('analytic_code', dataInvoice.analytic_code)
      setValue('devise', setCurrency(dataInvoice.currency))
      setValue('payment', setPayment(dataInvoice.payment_method))
      setValue('amount_initial_name', dataInvoice.amount_initial_name)
      setValue('fees_name', dataInvoice.fees_name)
      setValue('billing_date', dataInvoice.invoice_date)
      setValue('due_date', dataInvoice.due_date)
      setValue('text_total_with_taxes', dataInvoice?.text_total_with_taxes || '')
    }
  }, [dataInvoice])

  useEffect(() => {
    if (!dataInvoice) {
      setValue('general_description', analytic_code?.title)
    }
  }, [analytic_code])


  const {fields, append, remove} = useFieldArray({
    control,
    name: 'listFees'
  })

  useEffect(() => {
    if (dataInvoice && 'articles' in dataInvoice) {
      remove(indexList(fields))
      dataInvoice.articles.forEach((e) => {
        append({
          description: e.description,
          type: setFormat(e.type),
          rent: Number(e.amount_initial),
          fee: Number(e.fee),
          taxe: Number(e.taxe),
          amount_without_taxes: Number(e.amount_without_taxes),
          amount_with_taxes: Number(e.amount_with_taxes)
        })
      })
    }
  }, [dataInvoice])

  useEffect(() => {
    if (listFees) {
      handleTotalHT()
      handleTotalTTC()
      handleTotalString()
    }
  }, [listFees])

  const handleChange = (e, name, key = null) => {
    setValue(name, e)
    if (key) {
      handleAmountHT(key)
      handleAmountTTC(key)
    }
  }

  function handleAmountHT(key) {
    const rent = Number(getValues(`${key}.rent`))
    const fee = Number(getValues(`${key}.fee`))
    if (rent !== 0 && fee !== 0) {
      const amount = (rent * fee) / 100
      setValue(`${key}.amount_without_taxes`, amount)
    } else {
      setValue(`${key}.amount_without_taxes`, Number(0))
    }
  }

  function handleAmountTTC(key) {
    const taxe = Number(getValues(`${key}.taxe`))
    const amount_without_taxes = Number(getValues(`${key}.amount_without_taxes`))

    if (amount_without_taxes && amount_without_taxes !== 0 && taxe !== 0) {
      const amount = (amount_without_taxes + ((amount_without_taxes * taxe / 100)))
      setValue(`${key}.amount_with_taxes`, amount)
    } else {
      setValue(`${key}.amount_with_taxes`, Number(0))
    }
  }

  function handleTotalHT() {
    const listValues = listFees.map((e) => e.amount_without_taxes)
    const total = (accumulator, currentValue) => accumulator + currentValue
    const amount = Number(listValues.reduce(total, 0)).toFixed(2)
    setValue('total_without_taxes', amount)
  }

  function handleTotalTTC() {
    const listValues = listFees.map((e) => e.amount_with_taxes)
    const total = (accumulator, currentValue) => accumulator + currentValue

    setValue('total_with_taxes', Number(listValues.reduce(total, 0)).toFixed(2))
  }

  const indexList = (array) => array.map((e, index) => index)

  const setFeesAppend = () => {
    if (analytic_code?.value === 'IPR_2' && contract_name.asset_management_fees) {
      return Number(contract_name.asset_management_fees)
    }
    if (analytic_code?.value === 'IPR_3' && contract_name.property_management_fees) {
      return Number(contract_name.property_management_fees)
    } else {
      return 0
    }
  }

  function handleTotalString() {
    if (!textTotalWithTextManuallyHandled) {
      const total_with_taxes = useDevise(devise, getValues('total_with_taxes'))
      if (total_with_taxes === "0,00 €") return setValue('text_total_with_taxes', 'zéro')
      const split = `${total_with_taxes}`
        .replace('€', '')
        .replace(/\s+/g, '')
        .split(",")

      if (split.length === 2 && split[1] === "00") {
        return setValue('text_total_with_taxes', `${writtenNumber(Number(split[0]), {lang: 'fr'})} euros`)
      } else if (split.length === 2) {
        const number = writtenNumber(Number(split[0]), {lang: 'fr'});
        const cents = writtenNumber(Number(split[1].length === 2 ? split[1] : split[1] + "0"), {lang: 'fr'});
        return setValue('text_total_with_taxes', `${number} euros et ${cents} centimes`)
      }
    }
  }

  return (
    <PageContainer>
      <InfosContainer>
        {isLoading ?
          <>
            <Loader height="500px"/>
            <TextLoaderContainer>
              <Text variant="h6" marginBottom color="secondary">
                {dataInvoice?.status === 'DRAFT' ?
                  'Mise à jour de votre facture en cours...'
                  :
                  'Votre facture est en train d\'être générée...'
                }
              </Text>
            </TextLoaderContainer>
          </>
          :
          <form>
            <Paper elevation={1}>
              <TitleContainer>
                <Text variant="h5" marginBottom color="primary">Destinataire</Text>
              </TitleContainer>
              <ContentContainer>
                <Controller
                  name='contract_name'
                  control={control}
                  render={({value, onChange, ref}) => (
                    <SelectText
                      id="contract_name"
                      label="Mandat"
                      value={value}
                      width="400px"
                      onChange={(_, value) => {
                        remove(indexList(fields))
                        setValue('billing_entity', null)
                        onChange(value)
                      }}
                      options={data}
                      inputRef={ref}
                      optionKey={'contract_name'}
                    />
                  )}
                />
                {contract_name &&
                <Controller
                  name='billing_entity'
                  control={control}
                  render={({value, onChange, ref}) => (
                    <SelectText
                      id="billing_entity"
                      label="SPV"
                      value={value}
                      width="400px"
                      onChange={(_, value) => {
                        onChange(value)
                      }}
                      inputRef={ref}
                      options={getValues('contract_name')?.billing_entity}
                      optionKey={'company_name'}
                    />
                  )}
                />}
                {contract_name && billing_entity &&
                <>
                  <SpvContainer>
                    <div>
                      <Text color="primary" variant="subtitle2">Raison Sociale :</Text>
                      <div style={{width: '10px'}}/>
                      <Text variant="subtitle1">{getValues('billing_entity')?.company_name}</Text>
                    </div>
                    <div>
                      <Text color="primary" variant="subtitle2">Adresse :</Text>
                      <div style={{width: '10px'}}/>
                      <Text variant="subtitle1">{getValues('billing_entity')?.address}</Text>
                    </div>
                    <Text color="primary" variant="subtitle2">Site(s) :</Text>
                  </SpvContainer>
                  <ChipContainer>
                    {data.find(e => e?.contract_name === getValues('contract_name')?.contract_name)?.places.map((e, i) => {
                      return (
                        <div key={createKey(i)} style={{
                          margin: '3px 2px'
                        }}>
                          <Chip label={e.name} size="small"/>
                        </div>
                      )
                    })
                    }
                  </ChipContainer>
                </>
                }
              </ContentContainer>
              <Spacer large/>
              <TitleContainer>
                <Text variant="h5" marginBottom color="primary">Informations de facturation</Text>
              </TitleContainer>
              <ContentContainer>
                <Controller
                  name='analytic_code'
                  control={control}
                  render={({value, onChange, ref}) => (
                    <SelectText
                      label="Type"
                      id='analytic_code'
                      width='450px'
                      value={value}
                      onChange={(_, value) => {
                        remove(indexList(fields))
                        onChange(value)
                        setValue('general_description', value?.title)
                      }}
                      options={typeOptions}
                      inputRef={ref}
                      optionKey={'label'}
                    />
                  )}
                />
              </ContentContainer>
              {contract_name && billing_entity && analytic_code &&
              <>
                <ContentContainer>
                  <Controller
                    name='general_description'
                    control={control}
                    render={({value, onChange, ref}) => (
                      <InputText
                        width="950px"
                        multiline
                        inputRef={ref}
                        value={value}
                        id="general_description"
                        rowsMax={3}
                        onChange={onChange}
                        label="Description générale"
                      />
                    )}
                  />
                </ContentContainer>
                <ContentContainer>
                  <Controller
                    name='devise'
                    control={control}
                    render={({value, onChange, ref}) => (
                      <SelectText
                        required
                        id="devise"
                        inputRef={ref}
                        value={value}
                        options={deviseOptions}
                        optionKey={'label'}
                        onChange={(_, value) => {
                          setDevise(value.label)
                          onChange(value)
                        }}
                        label="Devise"
                      />
                    )}
                  />
                  <Controller
                    name='payment'
                    control={control}
                    render={({value, onChange, ref}) => (
                      <SelectText
                        required
                        id="payment"
                        inputRef={ref}
                        value={value}
                        optionKey={'label'}
                        options={payment}
                        onChange={(_, value) => {
                          onChange(value)
                        }}
                        label="Moyen de paiement"
                      />
                    )}
                  />
                </ContentContainer>
                <ContentContainer>
                  <Controller
                    name='fees_name'
                    control={control}
                    render={({value, onChange, ref}) => (
                      <InputText
                        width="300px"
                        inputRef={ref}
                        value={value}
                        id="fees_name"
                        onChange={(value) => {
                          if (fields.length > 0) {
                            remove(indexList(fields))
                          }
                          onChange(value)
                        }}
                        label="Intitulé (Honoraires)"
                      />
                    )}
                  />
                  <Controller
                    name='amount_initial_name'
                    control={control}
                    render={({value, onChange, ref}) => (
                      <InputText
                        width="300px"
                        inputRef={ref}
                        value={value}
                        id="amount_initial_name"
                        onChange={(value) => {
                          if (fields.length > 0) {
                            remove(indexList(fields))
                          }
                          onChange(value)
                        }}
                        label="Intitulé (Montant)"
                      />
                    )}
                  />
                </ContentContainer>
                <ContentContainer>
                  <Controller
                    name='billing_date'
                    control={control}
                    render={({value, onChange}) => (
                      <Calendar
                        required
                        id="billing_date"
                        defaultValue={value}
                        onChange={onChange}
                        label="Date de Facturation"
                      />
                    )}
                  />
                  <Controller
                    name='due_date'
                    control={control}
                    render={({value, onChange}) => (
                      <Calendar
                        required
                        id="due_date"
                        value={value}
                        defaultValue={value}
                        onChange={onChange}
                        label="Date d'échéance"
                      />
                    )}
                  />
                </ContentContainer>
                <Spacer large/>

                <TitleContainer>
                  <Text variant="h5" marginBottom color="primary">Articles</Text>
                </TitleContainer>
                {fields.map((item, index) => {
                  return (
                    <div key={`${index}-${item.id}`}>
                      <ContentContainer key={item.id}>
                        <RowContainer>
                          <NumberContainer>
                            <p>{index + 1}</p>
                          </NumberContainer>
                          <Row>
                            <Controller
                              name={`listFees[${index}].description`}
                              defaultValue={item?.description}
                              control={control}
                              render={({value, onChange, ref}) => (
                                <InputText
                                  width="945px"
                                  littlePadding
                                  id={`description-${item.id}`}
                                  multiline
                                  inputRef={ref}
                                  onChange={onChange}
                                  value={value}
                                  rowsMax={3}
                                  label="Description"
                                />)}
                            />
                            <div>
                              <Controller
                                name={`listFees[${index}].type`}
                                defaultValue={item?.type}
                                control={control}
                                render={({value, onChange, ref}) => (
                                  <SelectText
                                    label="Format"
                                    width='185px'
                                    size='small'
                                    id={`type-${item.id}`}
                                    littlePadding
                                    value={value}
                                    onChange={(_, value) => {
                                      if (value?.value === 'PERCENTAGE') {
                                        setValue(`listFees[${index}].fee`, 0)
                                      }
                                      if (value?.value === 'PERCENTAGE' && analytic_code?.value === 'IPR_2' && contract_name.asset_management_fees) {
                                        setValue(`listFees[${index}].fee`, contract_name?.asset_management_fees)
                                      }
                                      if (value?.value === 'PERCENTAGE' && analytic_code?.value === 'IPR_3' && contract_name.property_management_fees) {
                                        setValue(`listFees[${index}].fee`, contract_name?.property_management_fees)
                                      }
                                      if (value?.value === 'AMOUNT') {
                                        setValue(`listFees[${index}].fee`, 100)
                                      }
                                      setValue(`listFees[${index}].rent`, 0)
                                      setValue(`listFees[${index}].amount_without_taxes`, 0)
                                      setValue(`listFees[${index}].amount_with_taxes`, 0)
                                      onChange(value)
                                    }}
                                    options={formatOptions}
                                    inputRef={ref}
                                    optionKey={'label'}
                                  />
                                )}
                              />

                              <Controller
                                name={`listFees[${index}].rent`}
                                control={control}
                                defaultValue={item?.rent}
                                render={({value, ref}) => (
                                  <InputText
                                    required
                                    type="number"
                                    size='small'
                                    inputRef={ref}
                                    width="200px"
                                    id={`rent-${item.id}`}
                                    littlePadding
                                    icon={devise}
                                    value={value}
                                    onChange={(e) => {
                                      handleChange(e.target.value, `listFees[${index}].rent`, `listFees[${index}]`)
                                    }}
                                    label={cropAndAddDots(getValues('amount_initial_name'), 22)}
                                  />
                                )}
                              />
                              <Controller
                                name={`listFees[${index}].fee`}
                                defaultValue={item?.fee}
                                control={control}
                                render={({value, ref}) => (
                                  value === 100 ? null :
                                    <InputText
                                      required
                                      type="number"
                                      id={`fee-${item.id}`}
                                      icon="%"
                                      inputRef={ref}
                                      littlePadding
                                      size='small'
                                      width='120px'
                                      onChange={(e) => {
                                        handleChange(e.target.value, `listFees[${index}].fee`, `listFees[${index}]`)
                                      }
                                      }
                                      value={value}
                                      label={cropAndAddDots(getValues('fees_name'), 11)}
                                    />
                                )}
                              />
                              <Controller
                                name={`listFees[${index}].amount_without_taxes`}
                                defaultValue={item?.amount_without_taxes}
                                control={control}
                                render={({value, ref}) => (
                                  <InputText
                                    required
                                    size='small'
                                    inputRef={ref}
                                    width="160px"
                                    id={`amount_without_taxes-${item.id}`}
                                    value={useDevise(devise, value)}
                                    onChange={() => null}
                                    littlePadding
                                    label="Montant H.T."
                                  />
                                )}
                              />
                              <Controller
                                name={`listFees[${index}].taxe`}
                                defaultValue={item?.taxe}
                                control={control}
                                render={({value, ref}) => (
                                  <InputText
                                    required
                                    type="number"
                                    icon="%"
                                    id={`taxe-${item.id}`}
                                    littlePadding
                                    size='small'
                                    width='120px'
                                    onChange={(e) => {
                                      handleChange(e.target.value, `listFees[${index}].taxe`, `listFees[${index}]`)
                                    }}
                                    inputRef={ref}
                                    value={value}
                                    label="TVA"
                                  />)
                                }
                              />
                              <Controller
                                name={`listFees[${index}].amount_with_taxes`}
                                control={control}
                                defaultValue={item?.amount_with_taxes}
                                render={({value, ref}) => (
                                  <InputText
                                    required
                                    size='small'
                                    width="160px"
                                    inputRef={ref}
                                    id={`amount_with_taxes-${item.id}`}
                                    littlePadding
                                    onChange={() => null}
                                    value={useDevise(devise, value)}
                                    label="Montant T.T.C"
                                  />
                                )}
                              />
                            </div>
                          </Row>
                          <NumberContainer>
                            <IconButtonCustom onClick={() => remove(index)} edge='start' component="span">
                              <HighlightOffIcon/>
                            </IconButtonCustom>
                          </NumberContainer>
                        </RowContainer>
                      </ContentContainer>
                      <Spacer medium/>
                    </div>
                  )
                })}
                <Spacer small/>
                <ContentContainer>
                  <ButtonCustom
                    startIcon={<AddIcon/>}
                    background="blue"
                    onClick={() => {
                      append({
                        description: analytic_code.title,
                        type: formatOptions[0],
                        rent: Number(0),
                        fee: setFeesAppend(),
                        taxe: Number(20),
                        amount_without_taxes: Number(0),
                        amount_with_taxes: Number(0)
                      })
                    }}
                    disabled={!analytic_code}
                    label="Ajouter une ligne"
                  />
                  {dataInvoice?.status === 'DRAFT' && dataInvoice?.url &&
                  <ButtonCustom
                    label="Voir la facture"
                    background="orange"
                    onClick={togglePdf}
                    startIcon={<VisibilityIcon/>}
                  />
                  }
                </ContentContainer>

                {dataInvoice?.status === 'DRAFT' && dataInvoice?.url && openPdf &&
                <ContentContainer>
                  <PdfContainer>
                    <embed src={dataInvoice?.url} width="80%" height="1050" type='application/pdf'/>
                  </PdfContainer>
                </ContentContainer>
                }
                <ContentContainer style={{justifyContent: 'flex-end'}}>
                  <Controller
                    name="text_total_with_taxes"
                    control={control}
                    render={({value, ref, onChange}) => {
                      return (
                        <InputText
                          required
                          size='small'
                          width="auto"
                          inputRef={ref}
                          id="text_total_with_taxes"
                          littlePadding
                          value={value}
                          onChange={(event) => {
                            onChange(event.target.value)
                            setValue('text_total_with_taxes', event.target.value)
                            setTextTotalWithTextManuallyHandled(true)
                          }}
                          label="Phrase montant total"
                        />
                      )
                    }}
                  />
                  <Controller
                    name="total_without_taxes"
                    control={control}
                    render={({value, ref}) => (
                      <InputText
                        required
                        size='small'
                        width="200px"
                        inputRef={ref}
                        id="total_without_taxes"
                        littlePadding
                        onChange={() => null}
                        value={useDevise(devise, value)}
                        label="Montant total H.T."
                      />
                    )}
                  />
                  <Controller
                    name="total_with_taxes"
                    control={control}
                    render={({value, ref}) => (
                      <InputText
                        required
                        size='small'
                        width="200px"
                        inputRef={ref}
                        id="total_with_taxes"
                        littlePadding
                        onChange={() => null}
                        value={useDevise(devise, value)}
                        label="Montant total T.T.C"
                      />
                    )}
                  />
                </ContentContainer>
                <ContentContainer>
                  <ButtonSubmitContainer>
                    <ButtonCustom
                      width="150px"
                      startIcon={<CancelIcon/>}
                      background="dark"
                      onClick={() => history.push('/billing')}
                      label="Annuler"
                    />
                    <ButtonSaveInvoice fields={fields}
                                       handleSubmit={handleSubmit}
                                       getIsLoading={getIsLoading}
                                       forUpdate={dataInvoice?.status === 'DRAFT'}/>

                  </ButtonSubmitContainer>
                </ContentContainer>
              </>
              }
              <Spacer xLarge/>
            </Paper>
          </form>
        }
      </InfosContainer>
    </PageContainer>
  )
}

const InfosContainer = styled.div`
  width: 100%;
  margin: 20px 0;
`

const ButtonSubmitContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
`

const TitleContainer = styled.div`
  padding: 20px 30px;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 30px 0 30px;
  justify-content: flex-start;
`

const SpvContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 20px;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const RowContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border-left: 2px solid ${theme.grey};
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }
`

const NumberContainer = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    background: ${theme.blue};
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
  }
`
const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 90px;
  justify-content: flex-start;
`

const IconButtonCustom = styled(IconButton)`
  color: ${theme.red} !important;
`

const TextLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const PdfContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 50px 0;
`

export {
  NewBillContent
}
