import React, { useEffect } from 'react'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { Text } from '../../common/text'
import { InputText } from '../../common/inputs/inputText'
import { useForm } from 'react-hook-form'
import AddIcon from '@material-ui/icons/Add'
import { Loader } from '../../common/loader'
import { useContract } from '../../../hooks/home.hook'
import { useMutation, useQueryClient } from 'react-query'
import { ListPLaces } from './listPlaces'

function PlacesContainer({data, isLoading}) {
	const queryClient = useQueryClient()
	const {register, handleSubmit, setValue} = useForm()
	const {updateContract} = useContract()
	const {mutate} = useMutation(contract => updateContract(contract), {
		onMutate: async contract => {
			// annule les reqûetes en cours
			await queryClient.cancelQueries(['getContractWithId', contract.contractId])
			// capture la valeur de getContractWithId dans le cache afin de l'utiliser en cas de problème avec la requête
			const previousContract = queryClient.getQueryData(['getContractWithId', contract.contractId])
			// prépare l'objet d'update pour mettre à jours le cache
			const updated = {
				...previousContract,
				places: [...previousContract.places, {name: contract.form.places.create.name}]
			}
			// mise à jours du cache
			queryClient.setQueryData(['getContractWithId', contract.contractId], updated)
			// retourne dans le context des méthodes suivantes ces valeurs afin de pouvoir les utiliser
			return {previousContract, contract}
		},
		onError: (err, newTodo, context) => {
			// Si il y a une erreur avec l'API on récupère la valeur initiale de getContractWithId et on revient à cette état afin de ne pas
			// impacter le cache par l'erreur
			queryClient.setQueryData(
				['getContractWithId', context.contract.contractId],
				context.previousContract
			)
		},
		onSettled: () => {
			// une fois que tout est terminé on refetch la query getContractWithId afin d'avoir
			// de la vrai donnée dans le cache et non de la donnée d'affichage uniquement
			queryClient.invalidateQueries('getContractWithId')
		}
	})

	const onSubmit = (form) => {
		mutate({contractId: data.id, form: {places: {create: {name: form.places}}}})
	}

	const handleChange = (e, name) => {
		setValue(name, e)
	}

	useEffect(() => {
		register('places')
	}, [register])

	return (
		<>
			<TitleContainer>
				<Text variant="h5" marginBottom
				      color="primary">{isLoading ? null : data.places.length} {isLoading ? null : data.places.length > 1 ? 'sites' : 'site'}</Text>
				<FormContent onSubmit={handleSubmit(onSubmit)}>
					<InputText
						size="small"
						width="350px"
						required
						onChange={(e) => {
							handleChange(e.target.value, 'places')
						}}
						label="Ajouter un site"/>
					<IconButton edge='start' onClick={handleSubmit(onSubmit)} type="submit" color="primary" component="span">
						<AddIcon/>
					</IconButton>
				</FormContent>
			</TitleContainer>
			{isLoading ? <Loader/> :
				<ContentContainer>
					<ListPLaces data={data}/>
				</ContentContainer>
			}
		</>
	)
}

const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const FormContent = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`

export {
	PlacesContainer
}