import {deviseOptions, formatOptions, payment} from '../../data/data'

const createKey = (input) => {
  return input ? input.toString().trim() : Math.random() * (200000)
}

const getParamUrl = (param) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(param)
}

const useDevise = (devise, _value) => {
  const value = Number(_value).toFixed(2)
  switch (devise) {
    case '€' : {
      return new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency', currency: 'EUR'
      }).format(value)
    }
    case '$' : {
      return new Intl.NumberFormat('us-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency', currency: 'USD'
      }).format(value)
    }
    default : {
      return new Intl.NumberFormat('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value)
    }
  }
}

const cropAndAddDots = (str, len) => {
  if (len && str && str.length > len) {
    return str.substring(0, len - 1) + '...'
  } else return str
}

const setCurrency = (currencyValue) => {
  switch (currencyValue) {
    case 'EUR':
      return deviseOptions[0]
    case 'USD':
      return deviseOptions[1]
    default:
      return deviseOptions[0]
  }
}

const setPayment = (PaymentValue) => {
  switch (PaymentValue) {
    case 'BANK_TRANSFER':
      return payment[0]
    case 'CHEQUE':
      return payment[1]
    default:
      return payment[0]
  }
}

const setFormat = (formatValue) => {
  switch (formatValue) {
    case 'PERCENTAGE':
      return formatOptions[0]
    case 'AMOUNT':
      return formatOptions[1]
    default:
      return formatOptions[0]
  }
}

export {
  createKey,
  getParamUrl,
  useDevise,
  cropAndAddDots,
  setCurrency,
  setPayment,
  setFormat
}
