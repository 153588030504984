import styled from 'styled-components'
import {theme} from '../../config/theme'
import React from 'react'
import {Chip} from "@mui/material";
import {withTheme} from "@mui/styles";

function ChipCustom({background, label}) {
  return (
    <ChipElem
      label={label}
      background={background}
      size="small"
    />
  )
}

const ChipElem = withTheme(styled(Chip)`
  background: ${props => props.background ? theme[props.background] : null} !important;
  color: ${props => props.background && props.background != 'yellow' ? 'white' : null} !important;
  font-weight: bold !important;
`)

export {
  ChipCustom
}
