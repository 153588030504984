import React from 'react'
import {ProvidersContent} from '../components/contentPages/providers'

function Providers() {
  return (
    <ProvidersContent/>
  )
}

export
{
  Providers
}
