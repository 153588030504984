import React, {useState} from 'react'
import {PageContainer} from '../../layout/drawerMenuOptions'
import {Paper, Snackbar} from '@material-ui/core'
import {Text} from '../../common/text'
import styled from 'styled-components'
import {DropzoneDialog} from "material-ui-dropzone";
import {ButtonCustom} from "../../common/button";
import AddIcon from "@material-ui/icons/Add";
import {useOverlap} from "../../../hooks/overlap.hook";
import {useMutation} from "react-query";
import {Alert} from "@material-ui/lab";
import {Spacer} from "../../common/spacer";

function OverlapContent() {
  const [open, setOpen] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const toggle = () => {
    setOpen(open => !open)
  }
  const {uploadFiles} = useOverlap()

  const {mutate} = useMutation("overlap", (formData) => uploadFiles(formData), {
    onSuccess: (data) => {
      toggle(false)
      setSuccessMessage(data.data.message)
    },
    onError: () => {
      toggle(false)
      setErrorMessage("Une erreur est survenue !")
    }
  })

  const handleSave = (files) => {
    const data = new FormData()
    files.forEach(file => {
      data.append(file.name, file)
    })
    mutate(data)
  }

  return (
    <PageContainer>
      <OverlapContainer>
        <InfosContainer>
          <Paper elevation={1} style={{width: '100%'}}>
            <TitleContainer>
              <Text variant="h5" marginBottom
                    color="primary">Recoupement</Text>
     {/*         <ButtonCustom
                onClick={toggle}
                background="blue"
                height="40"
                startIcon={<AddIcon/>}
                label="Télécharger un relevé bancaire"
              />*/}
            </TitleContainer>
            <ContentContainer>
              <DropzoneDialog
                open={open}
                acceptedFiles={[".csv"]}
                onSave={(e) => handleSave(e)}
                showPreviews={true}
                filesLimit={1}
                maxFileSize={5000000}
                dialogTitle='Télécharger un relevé bancaire'
                cancelButtonText='Annuler'
                submitButtonText='Valider'
                onClose={() => toggle(false)}
                previewText="Aperçu :"
                getFileAddedMessage={() => 'Le fichier a été ajouté avec succès'}
                getFileRemovedMessage={() => 'Le fichier a été supprimé'}
                getDropRejectMessage={() => 'Le fichier a été rejeté..'}
                getFileLimitExceedMessage={() => 'Votre fichier dépasse la taille limite autorisée'}
                dropzoneText="Glissez et déposez votre fichier dans la zone"
              />
              <Snackbar
                open={successMessage}
                onClose={() => setSuccessMessage(null)}
                autoHideDuration={6000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Alert variant="filled" severity="success">
                  {successMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                open={errorMessage}
                onClose={() => setErrorMessage(null)}
                autoHideDuration={6000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
              <TextContainer>
                <Text color="secondary">Cette fonctionnalité permet de recouper automatiquement les montants crédités
                  sur le relevé de
                  compte bancaire.</Text>
                <Spacer small/>
                <Text color="secondary">Si un numéro de facture présent sur le relevé est recoupé avec une facture
                  existante, celle-ci
                  sera automatiquement mise à jour en statut "Payé", si le montant reçu est identique à celui facturé.
                </Text>
                <Spacer small/>
                <Text color="secondary">Si le montant reçu est différent de celui facturé, la facture sera mise à jour
                  en statut "Paiement non
                  conforme".</Text>
                <Spacer small/>
                <Text color="secondary">Si le numéro de facture ne peut pas être récupéré sur le relevé bancaire, aucun
                  recoupement ne sera effectué.</Text>
                <Spacer small/>
                <Text customcolor="red" variant="h6"> ⚠ Le relevé doit être téléchargé au format csv.</Text>
              </TextContainer>
            </ContentContainer>
          </Paper>
        </InfosContainer>
      </OverlapContainer>
    </PageContainer>
  )
}

const OverlapContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const InfosContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 30px 50px 30px;
  justify-content: center;
`

const TextContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
`

export {
  OverlapContent
}






